import { API, Amplify, Auth } from "aws-amplify";

Amplify.configure({
	aws_cognito_region: "us-east-2",
	Auth: {
		identityPoolId: "us-east-2:4afb7022-c53b-431a-b5ee-7c2499ce04f5", // REQUIRED - Amazon Cognito Identity Pool ID
		region: "us-east-2", // REQUIRED - Amazon Cognito Region
		userPoolId: "us-east-2_PHjtSQXtJ",
		userPoolWebClientId: "7d3fniusemp2e47u8a7mhkti4t",
	},
	API: {
		endpoints: [
			{
				name: "apiGateway",
				endpoint: "https://zfmaj09w42.execute-api.us-east-2.amazonaws.com/dev",
			},
		],
	},
});

export async function getSchools() {
	const apiName = "apiGateway";
	const path = `/schools`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
	};

	return API.get(apiName, path, myInit);
}

export async function getSchoolById(id) {
	const apiName = "apiGateway";
	const path = `/schools?school_id='${id}'`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
	};

	return API.get(apiName, path, myInit);
}

export async function createSchool(body) {
	const apiName = "apiGateway";
	const path = `/schools`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.put(apiName, path, myInit);
}

export async function editSchool(body) {
	const apiName = "apiGateway";
	const path = `/schools`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.post(apiName, path, myInit);
}
