import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function EditPasswordDialog({ open, handleCancel, handleSubmit, user }) {
  const [studentData, setStudentData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let properties = ["newPassword"];

  const handleClose = (update) => {
    if (update) {
      let student = {
        ...studentData,
        username: user.cognito_username,
      };
      handleSubmit(student);
    } else {
      handleCancel();
    }
    setStudentData({});
  };

  const handleStringChange = (event) => {
    event.persist();
    setStudentData({
      ...studentData,
      [event.target.name]: String(event.target.value),
    });
  };

  let meetsRequiremnts = false;
  for (let p of properties) {
    if (!Object.hasOwn(studentData, p)) {
      meetsRequiremnts = false;
      break;
    }
    meetsRequiremnts = true;
  }

  return (
    <Box sx={{ padding: "15px" }}>
      <Dialog open={open}>
        <DialogTitle>
          <Typography variant="h6" align="center">
            Edit Password
          </Typography>
        </DialogTitle>
        <DialogContent align="left">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Student: {user.first_name} {user.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField name="code" margin="dense" fullWidth label="Email Code" type="string" variant="outlined" onChange={handleStringChange} required />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  onChange={handleStringChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" disabled={!meetsRequiremnts}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
