import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function ConfirmStudentDialog({ open, handleCancel, handleSubmit, user }) {
  const [studentData, setStudentData] = useState({});
  let properties = ["code"];

  const handleClose = (update) => {
    if (update) {
      let student = {
        ...studentData,
        user_name: user.cognito_username,
        id: user.id,
      };
      handleSubmit(student);
    } else {
      handleCancel();
    }
    setStudentData({});
  };

  const handleStringChange = (event) => {
    event.persist();
    setStudentData({
      ...studentData,
      [event.target.name]: String(event.target.value),
    });
  };

  let meetsRequiremnts = false;
  for (let p of properties) {
    if (!Object.hasOwn(studentData, p)) {
      meetsRequiremnts = false;
      break;
    }
    meetsRequiremnts = true;
  }

  return (
    <Box sx={{ padding: "15px" }}>
      <Dialog open={open}>
        <DialogTitle>
          <Typography variant="h6" align="center">
            Confirm Student
          </Typography>
        </DialogTitle>
        <DialogContent align="left" sx={{ maxWidth: "400px", minWidth: "400px" }}>
          <Grid container spacing={2} sx={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <TextField name="code" margin="dense" fullWidth label="Confirmation Code" type="string" variant="outlined" onChange={handleStringChange} required />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" disabled={!meetsRequiremnts}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
