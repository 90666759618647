import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import CssBaseline from "@mui/material/CssBaseline";
import { Amplify } from "aws-amplify";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import NavBar from "./components/navbar";
import LoadSpinner from "./config/loading";
import { ToastContextProvider } from "./config/toast";
// add amplify and cognito
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getRefData } from "./api/refdataApi";
import { getUserById } from "./api/usersApi";
// Configure Amplify in index file or root file
Amplify.configure({
  aws_cognito_region: "us-east-2",
  Auth: {
    identityPoolId: "us-east-2:4afb7022-c53b-431a-b5ee-7c2499ce04f5", // REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-2", // REQUIRED - Amazon Cognito Region
    userPoolId: "us-east-2_PHjtSQXtJ",
    userPoolWebClientId: "7d3fniusemp2e47u8a7mhkti4t",
  },
});

async function GetRefData() {
  const data = await getRefData();
  return data;
}

const themeLight = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",

          "&.Mui-selected": {
            backgroundColor: "#6967673b",
          },
        },
      },
    },
  },
});

const themeDark = createTheme({
  palette: {
    background: {
      default: "#222222",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

async function GetUser(user) {
  const data = await getUserById(user);
  let parsed = JSON.parse(data.body)[0];
  return parsed;
}

export default function App() {
  const [light, setLight] = useState(true);
  const [bootstrapping, setBootstrapping] = useState(true);
  const [appUser, setAppUser] = useState({});
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const result = await GetUser(user?.username);
          await GetRefData();
          setBootstrapping(false);
          setAppUser(result);
        } catch {}
      };
      fetchData();
    }
  }, [user]);

  const formFields = {
    signUp: {
      username: {
        order: 1,
      },
      email: {
        order: 2,
      },

      password: {
        order: 3,
      },
      confirm_password: {
        order: 4,
      },
    },
  };

  return (
    <Authenticator formFields={formFields} signUpAttributes={["email"]} variation="modal">
      <BrowserRouter>
        <ThemeProvider theme={light ? themeLight : themeDark}>
          <ToastContextProvider>
            <CssBaseline />
            {bootstrapping ? <LoadSpinner /> : <NavBar signOut={signOut} user={appUser} />}
          </ToastContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Authenticator>
  );
}
