export const gameTypes = Object.freeze([
  {
    id: 1,
    name: "Pattern",
  },
]);

export const IconCats = Object.freeze([
  {
    id: 1,
    name: "Growth",
  },
  {
    id: 2,
    name: "Geometry",
  },
  {
    id: 3,
    name: "Symmetry",
  },
  {
    id: 4,
    name: "Building Blocks",
  },
]);

export const games = Object.freeze([
  {
    id: 1,
    type: 1,
    questionAmount: 10,
    name: "Pattern",
    images: [
      {
        id: 1,
        name: "Bike Tire",
        percent_correct: 56,
        url: "/images/pattern-images/biketire.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 2,
        name: "Bubble",
        percent_correct: 56,
        url: "/images/pattern-images/bubble.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 3,
        name: "Corn",
        percent_correct: 56,
        url: "/images/pattern-images/corn.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 4,
        name: "Cracked",
        percent_correct: 56,
        url: "/images/pattern-images/cracked.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 5,
        name: "CrownFlower",
        percent_correct: 56,
        url: "/images/pattern-images/crownflower.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 6,
        name: "DNA",
        percent_correct: 56,
        url: "/images/pattern-images/dna.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 7,
        name: "drum",
        percent_correct: 56,
        url: "/images/pattern-images/drum.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 8,
        name: "Fan Coral",
        percent_correct: 56,
        url: "/images/pattern-images/fancoral.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 9,
        name: "Earth",
        percent_correct: 56,
        url: "/images/pattern-images/earth.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 10,
        name: "feather",
        percent_correct: 56,
        url: "/images/pattern-images/feather.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 11,
        name: "Galaxy GS",
        percent_correct: 56,
        url: "/images/pattern-images/galaxygrayscale.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 12,
        name: "Grapefruit",
        percent_correct: 56,
        url: "/images/pattern-images/grapefruit.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 13,
        name: "Hex Nut",
        percent_correct: 56,
        url: "/images/pattern-images/hexnut.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 14,
        name: "Leek",
        percent_correct: 56,
        url: "/images/pattern-images/leek.jpg",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
    ],
  },
  {
    id: 2,
    type: 1,
    questionAmount: 15,
    name: "Test Data",
    images: [
      {
        id: 1,
        name: "Bees",
        percent_correct: 56,
        url: "/images/pattern-images/bees.png",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 2,
        name: "DNA",
        percent_correct: 56,
        url: "/images/pattern-images/dna.png",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 3,
        name: "Sports",
        percent_correct: 56,
        url: "/images/pattern-images/sports.png",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
      {
        id: 4,
        name: "Nature",
        percent_correct: 56,
        url: "/images/pattern-images/nature.png",
        scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
      },
    ],
  },
]);

export const students = Object.freeze([
  {
    id: 1,
    first_name: "Lennard",
    last_name: "Ablett",
    email: "lablett0@google.com.br",
    grade: 1,
    game_results: [
      {
        game: { id: 1, name: "Pattern" },
        date_time: "08/29/2023",
        score: { scored: 45, possible: 50 },
        results: [
          {
            image: {
              id: 1,
              name: "Bike Tire",
              percent_correct: 56,
              url: "/images/pattern-images/biketire.jpg",
              scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
            },
            total_time: 30,
            click_time: [7, 8, 15],
            possible_score: 9,
            student_score: [
              { value: 3, group: { id: 3, name: "Symmetry" } },
              { value: 1, group: { id: 2, name: "Geometry" } },
            ],
            correct_icons: [
              {
                id: 1,
                name: "1 point",
                url: "/images/icons/point1.png",
                sortOrder: 1,
                score: 5,
                group: {
                  id: 4,
                  name: "Building Blocks",
                },
              },
              {
                id: 4,
                name: "Asymmetry",
                url: "/images/icons/asymmetry.png",
                sortOrder: 17,
                score: 3,
                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 7,
                name: "Pentagon",
                score: 1,
                url: "/images/icons/pentagon.png",
                sortOrder: 28,
                group: {
                  id: 2,
                  name: "Geometry",
                },
              },
            ],
            selected_icons: [
              {
                id: 4,
                name: "Asymmetry",
                url: "/images/icons/asymmetry.png",
                sortOrder: 17,
                score: 3,
                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 6,
                name: "Branch",
                url: "/images/icons/branch.png",
                sortOrder: 11,
                score: 0,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
              {
                id: 7,
                name: "Pentagon",
                url: "/images/icons/pentagon.png",
                sortOrder: 28,
                score: 1,
                group: {
                  id: 2,
                  name: "Geometry",
                },
              },
            ],
          },
          {
            image: { id: 2, name: "Bubble", percent_correct: 56, url: "/images/pattern-images/bubble.jpg", scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }] },
            total_time: 44,
            possible_score: 9,
            student_score: [{ value: 5, group: { id: 1, name: "Growth" } }],
            click_time: [12, 20, 12],
            correct_icons: [
              {
                id: 6,
                name: "Branch",
                url: "/images/icons/branch.png",
                sortOrder: 11,
                score: 5,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
              {
                id: 7,
                name: "Pentagon",
                url: "/images/icons/pentagon.png",
                sortOrder: 28,
                score: 3,
                group: {
                  id: 2,
                  name: "Geometry",
                },
              },
              {
                id: 8,
                name: "Spiral",
                url: "/images/icons/spiral.png",
                sortOrder: 10,
                score: 1,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
            ],
            selected_icons: [
              {
                id: 4,
                name: "Asymmetry",
                url: "/images/icons/asymmetry.png",
                sortOrder: 17,
                score: 0,
                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 5,
                name: "bilat",
                url: "/images/icons/bilat.png",
                score: 0,
                sortOrder: 19,
                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 6,
                name: "Branch",
                url: "/images/icons/branch.png",
                score: 5,
                sortOrder: 11,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
            ],
          },
        ],
      },
      {
        game: { id: 1, name: "Pattern" },
        date_time: "06/29/2023",
        score: { scored: 41, possible: 50 },

        results: [
          {
            image: {
              id: 1,
              name: "Bike Tire",
              percent_correct: 56,
              url: "/images/pattern-images/biketire.jpg",
              scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }],
            },
            total_time: 44,
            click_time: [14, 10, 20],
            possible_score: 9,
            student_score: [
              { value: 3, group: { id: 3, name: "Symmetry" } },
              { value: 1, group: { id: 2, name: "Geometry" } },
            ],
            correct_icons: [
              {
                id: 1,
                name: "1 point",
                url: "/images/icons/point1.png",
                sortOrder: 1,
                score: 5,
                group: {
                  id: 4,
                  name: "Building Blocks",
                },
              },
              {
                id: 4,
                name: "Asymmetry",
                url: "/images/icons/asymmetry.png",
                sortOrder: 17,
                score: 3,
                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 7,
                name: "Pentagon",
                url: "/images/icons/pentagon.png",
                sortOrder: 28,
                score: 1,
                group: {
                  id: 2,
                  name: "Geometry",
                },
              },
            ],
            selected_icons: [
              {
                id: 4,
                name: "Asymmetry",
                url: "/images/icons/asymmetry.png",
                sortOrder: 17,
                score: 3,
                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 6,
                name: "Branch",
                url: "/images/icons/branch.png",
                sortOrder: 11,
                score: 0,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
              {
                id: 7,
                name: "Pentagon",
                url: "/images/icons/pentagon.png",
                score: 1,
                sortOrder: 28,
                group: {
                  id: 2,
                  name: "Geometry",
                },
              },
            ],
          },
          {
            image: { id: 2, name: "Bubble", percent_correct: 56, url: "/images/pattern-images/bubble.jpg", scoring: [{ 1: 3 }, { 2: 1 }, { 3: 2 }] },
            total_time: 33,
            click_time: [7, 15, 11],
            possible_score: 9,
            student_score: [{ value: 5, group: { id: 1, name: "Growth" } }],
            correct_icons: [
              {
                id: 6,
                name: "Branch",
                url: "/images/icons/branch.png",
                sortOrder: 11,
                score: 5,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
              {
                id: 7,
                name: "Pentagon",
                url: "/images/icons/pentagon.png",
                sortOrder: 28,
                score: 3,

                group: {
                  id: 2,
                  name: "Geometry",
                },
              },
              {
                id: 8,
                name: "Spiral",
                score: 1,

                url: "/images/icons/spiral.png",
                sortOrder: 10,
                group: {
                  id: 1,
                  name: "Growth",
                },
              },
            ],
            selected_icons: [
              {
                id: 4,
                name: "Asymmetry",
                url: "/images/icons/asymmetry.png",
                sortOrder: 17,
                score: 0,

                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 5,
                name: "bilat",
                url: "/images/icons/bilat.png",
                sortOrder: 19,
                score: 0,

                group: {
                  id: 3,
                  name: "Symmetry",
                },
              },
              {
                id: 6,
                name: "Branch",
                url: "/images/icons/branch.png",
                sortOrder: 11,
                score: 5,

                group: {
                  id: 1,
                  name: "Growth",
                },
              },
            ],
          },
        ],
      },
    ],
    games_assigned: [{ id: 1, name: "Pattern" }],
  },
  { id: 2, first_name: "Laverne", last_name: "Gehrtz", email: "lgehrtz1@businesswire.com", grade: 3, game_results: [], games_assigned: [{ id: 1, name: "Pattern" }] },
  { id: 3, first_name: "Domeniga", last_name: "Inkster", email: "dinkster2@mit.edu", grade: 3, game_results: [], games_assigned: [] },
  { id: 4, first_name: "Clare", last_name: "Hartil", email: "chartil3@huffingtonpost.com", grade: 8, game_results: [], games_assigned: [{ id: 1, name: "Pattern" }] },
  { id: 5, first_name: "Dud", last_name: "Pendered", email: "dpendered4@wisc.edu", grade: 3, game_results: [], games_assigned: [{ id: 1, name: "Pattern" }] },
  { id: 6, first_name: "Freddie", last_name: "Attrie", email: "fattrie5@last.fm", grade: 4, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  { id: 7, first_name: "Sampson", last_name: "Grundwater", email: "sgrundwater6@yelp.com", grade: 3, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  { id: 8, first_name: "Maia", last_name: "Woodhead", email: "mwoodhead7@bloglovin.com", grade: 1, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  { id: 9, first_name: "Sharla", last_name: "Golagley", email: "sgolagley8@discuz.net", grade: 7, game_results: [], games_assigned: [{ id: 1, name: "Pattern" }] },
  { id: 10, first_name: "Justina", last_name: "Slide", email: "jslide9@ebay.co.uk", grade: 3, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  { id: 11, first_name: "Denni", last_name: "Bogies", email: "dbogiesa@prweb.com", grade: 6, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  {
    id: 12,
    first_name: "Leland",
    last_name: "Adamsson",
    email: "ladamssonb@nsw.gov.au",
    grade: 7,
    game_results: [],
    games_assigned: [
      { id: 1, name: "Pattern" },
      { id: 2, name: "Rotation" },
    ],
  },
  { id: 13, first_name: "Willi", last_name: "Rambaut", email: "wrambautc@yahoo.co.jp", grade: 6, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  { id: 14, first_name: "Moreen", last_name: "Giraudou", email: "mgiraudoud@tinyurl.com", grade: 1, game_results: [], games_assigned: [{ id: 2, name: "Rotation" }] },
  {
    id: 15,
    first_name: "Mozes",
    last_name: "McIlvaney",
    email: "mmcilvaneye@spotify.com",
    grade: 7,
    game_results: [],
    games_assigned: [
      { id: 1, name: "Pattern" },
      { id: 2, name: "Rotation" },
    ],
  },
]);

export const student_cols = Object.freeze([
  {
    headerName: "First Name",
    field: "first_name",
    width: 150,
  },

  {
    headerName: "Last Name",
    field: "last_name",
    width: 150,
  },

  {
    headerName: "User Name",
    field: "cognito_username",
    width: 200,
  },
  {
    headerName: "School",
    field: "school_name",
    valueGetter: (params) => {
      return `${params.row.school[`school_name`]}`;
    },
    width: 175,
  },

  {
    headerName: "Email",
    field: "email",
    width: 250,
  },

  {
    headerName: "Grade",
    field: "grade",
    width: 100,
  },

  {
    headerName: "Games Assigned",
    field: "games_assigned",
    width: 190,
    flex: 1,
    valueGetter: ({ value }) => {
      return value.flatMap((v) => `${v.game_name}`).join(", ");
    },
  },
]);

export const icons = Object.freeze([
  {
    id: 25,
    name: "point1",
    url: "/images/icons/point1.png",
    sortOrder: 25,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 26,
    name: "point2",
    url: "/images/icons/point_2.png",
    sortOrder: 26,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 29,
    name: "angle",
    url: "/images/icons/angle.png",
    sortOrder: 29,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 16,
    name: "asymmetry",
    url: "/images/icons/asymmetry.png",
    sortOrder: 16,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 18,
    name: "bilat",
    url: "/images/icons/bilat.png",
    sortOrder: 18,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 3,
    name: "branch",
    url: "/images/icons/branch.png",
    sortOrder: 3,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 11,
    name: "pentagon",
    url: "/images/icons/pentagon.png",
    sortOrder: 11,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 2,
    name: "spiral",
    url: "/images/icons/spiral.png",
    sortOrder: 2,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 10,
    name: "square",
    url: "/images/icons/square.png",
    sortOrder: 10,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 21,
    name: "star",
    url: "/images/icons/star.png",
    sortOrder: 21,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 17,
    name: "chirality",
    url: "/images/icons/chirality.png",
    sortOrder: 17,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 14,
    name: "circle",
    url: "/images/icons/circle.png",
    sortOrder: 14,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 28,
    name: "curve",
    url: "/images/icons/curve.png",
    sortOrder: 28,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 15,
    name: "elipse",
    url: "/images/icons/elipse.png",
    sortOrder: 15,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 1,
    name: "explosion",
    url: "/images/icons/explosion.png",
    sortOrder: 1,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 31,
    name: "fraction",
    url: "/images/icons/fraction.png",
    sortOrder: 31,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 12,
    name: "hexagon",
    url: "/images/icons/hexagon.png",
    sortOrder: 12,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 32,
    name: "infiniti",
    url: "/images/icons/infiniti.png",
    sortOrder: 32,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 27,
    name: "line",
    url: "/images/icons/line.png",
    sortOrder: 27,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 4,
    name: "meander",
    url: "/images/icons/meander.png",
    sortOrder: 4,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 8,
    name: "bubble",
    url: "/images/icons/bubble.png",
    sortOrder: 8,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 30,
    name: "parabola",
    url: "/images/icons/parabola.png",
    sortOrder: 30,
    group: {
      id: 4,
      name: "Building Blocks",
    },
  },
  {
    id: 6,
    name: "parallel",
    url: "/images/icons/parallel.png",
    sortOrder: 6,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 22,
    name: "symmetric6",
    url: "/images/icons/symmetric6.png",
    sortOrder: 22,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 23,
    name: "symmetric7",
    url: "/images/icons/symmetric7.png",
    sortOrder: 23,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 24,
    name: "symmetric8",
    url: "/images/icons/symmetric8.png",
    sortOrder: 24,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 7,
    name: "tilling",
    url: "/images/icons/tilling.png",
    sortOrder: 7,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 9,
    name: "triangle",
    url: "/images/icons/triangle.png",
    sortOrder: 9,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 13,
    name: "octagon",
    url: "/images/icons/octagon.png",
    sortOrder: 13,
    group: {
      id: 2,
      name: "Geometry",
    },
  },
  {
    id: 5,
    name: "wave",
    url: "/images/icons/wave.png",
    sortOrder: 5,
    group: {
      id: 1,
      name: "Growth",
    },
  },
  {
    id: 19,
    name: "symmetric3",
    url: "/images/icons/symmetric3.png",
    sortOrder: 19,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
  {
    id: 20,
    name: "symmetric4",
    url: "/images/icons/symmetric4.png",
    sortOrder: 20,
    group: {
      id: 3,
      name: "Symmetry",
    },
  },
]);

export const TOAST_SETTINGS = Object.freeze({
  duration: 5000,
  severity: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
  },
  anchorOrigin: {
    topCenter: {
      vertical: "top",
      horizontal: "center",
    },
    topRight: {
      vertical: "top",
      horizontal: "right",
    },
    bottomRight: {
      vertical: "bottom",
      horizontal: "right",
    },
    bottomCenter: {
      vertical: "bottom",
      horizontal: "center",
    },
    bottomLeft: {
      vertical: "bottom",
      horizontal: "left",
    },
    topLeft: {
      vertical: "top",
      horizontal: "left",
    },
  },
});
