import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { setGameTypes } from "../api/refdataApi";

export default function AddGame({ open, handleCancel, handleSubmit }) {
	const [gameData, setGameData] = useState({});
	const [gameType, setGameType] = useState(1);

	useEffect(() => {
		if (open) {
			setGameData({});
			setGameType(1);
		}
	}, [open]);

	const handleClose = (update) => {
		if (update) {
			let game = {
				...gameData,
				game_type_id: gameType,
			};
			handleSubmit(game);
		} else {
			handleCancel();
		}
	};

	const handleStringChange = (event) => {
		event.persist();
		setGameData({
			...gameData,
			[event.target.name]: String(event.target.value),
		});
	};

	const handleNumberChange = (event) => {
		event.persist();
		setGameData({
			...gameData,
			[event.target.name]: Number(event.target.value),
		});
	};

	const handleGameTypeChange = (event) => {
		setGameType(Number(event.target.value));
	};

	return (
		<Box sx={{ padding: "15px" }}>
			<Dialog open={open}>
				<DialogTitle>
					<Typography variant="h6" align="center">
						Add Game
					</Typography>
				</DialogTitle>
				<DialogContent align="left">
					<Grid container spacing={2} sx={{ marginTop: "5px" }}>
						<Grid item xs={12}>
							<TextField name="name" margin="dense" fullWidth label="Game Name" type="string" variant="outlined" onChange={handleStringChange} required />
						</Grid>
						<Grid item xs={12}>
							<TextField name="numberOfQuestions" margin="dense" fullWidth label="Number of Questions" type="number" variant="outlined" onChange={handleNumberChange} required />
						</Grid>
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth sx={{ mt: "8px" }}>
								<InputLabel id="contact-type-label">Game Type *</InputLabel>
								<Select name="game_type_id" value={gameType} onChange={handleGameTypeChange} fullWidth={true} label="Game Type *">
									{setGameTypes().map((type) => {
										return (
											<MenuItem key={type.game_type_id} value={type.game_type_id}>
												{type.type_name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={() => handleClose(true)} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
