import { Box, FormControl, FormGroup, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";
//import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CheckIcon from "@mui/icons-material/Check";
import KeyIcon from "@mui/icons-material/Key";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { DataGrid, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Amplify, Auth } from "aws-amplify";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setGames } from "../api/refdataApi";
import { assignGames, createUser, editUser, getUsers } from "../api/usersApi";
import { TOAST_SETTINGS, student_cols } from "../config/constants";
import useToastContext from "../config/useToastContext";
import AddStudentDialog from "../dialogs/addStudent";
import ConfirmStudentDialog from "../dialogs/confirm";
import EditPasswordDialog from "../dialogs/editPassword";
import StudentInfoDialog from "../dialogs/studentInfo";

let colVisModel = {
	email: false,
	last_name: false,
};

Amplify.configure({
	aws_cognito_region: "us-west-2",
	Auth: {
		identityPoolId: "us-west-2:b1edff8c-7ab4-4757-b91a-6f3db23408e2", // REQUIRED - Amazon Cognito Identity Pool ID
		region: "us-west-2", // REQUIRED - Amazon Cognito Region
		userPoolId: "us-west-2_sXLDbiySM",
		userPoolWebClientId: "3lh3i1cfsc7agu02dvtnatcpj6",
	},
});

async function createNewUser(newUser, user) {
	let tempCogUser = {
		...newUser,
		cognito_username: user.username,
	};

	const response = await createUser(tempCogUser);
	return response;
}

async function EditUser(user) {
	const response = await editUser(user);
	return response;
}

async function getStudents() {
	const response = await getUsers();
	// let parsed = JSON.parse(response.body).filter((item) => item.is_teacher != 1);
	let parsed = JSON.parse(response.body);
	return parsed;
}

async function assignUserGames(body) {
	const response = await assignGames(body);
	return response;
}

async function editStudent(body) {
	const response = await editUser(body);
	return response;
}

function ManageStudents({ navUser }) {
	const [data, setData] = useState([]);
	const [columns, setColumns] = useState(student_cols);
	const [filterModel, setFilterModel] = useState({
		items: [],
	});
	const [showCreateDialog, setShowCreateDialog] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [showEditPassword, setShowEditPasswordDialog] = useState(false);
	const [showStudentInfoDialog, setShowStudentInfoDialog] = useState(false);
	const [selectedStudent, setSelectedStudent] = useState({});
	const addToast = useToastContext();
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [gameAssignModel, setGameAssignModel] = useState([]);
	const navigate = useNavigate();
	const games = useMemo(() => setGames());
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(colVisModel);

	async function confirmSignUp(user) {
		try {
			await Auth.confirmSignUp(user.user_name, user.code);
			let temp = {
				user_id: user.id,
				is_confirmed: 1,
			};
			const response = await editStudent(temp);
			if (response.statusCode === 200) {
				addToast({
					message: "User was successfully confirmed.",
					severity: TOAST_SETTINGS.severity.SUCCESS,
				});
				setRefresh(true);
				setShowConfirmDialog(false);
			} else {
				addToast({
					message: "There was an error confirming the user.",
					severity: TOAST_SETTINGS.severity.ERROR,
				});
			}
		} catch (error) {
			addToast({
				message: "There was an error confirming the user.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
	}

	const [refresh, setRefresh] = useState(false);

	async function forgotPassword(username) {
		try {
			await Auth.forgotPassword(username);
			setShowEditPasswordDialog(true);
		} catch (err) {
			console.log(err);
		}
	}

	const changeFilter = (newFilter) => {
		setFilterModel(newFilter);
	};

	const handleSubmit = async (studentData) => {
		try {
			await Auth.forgotPasswordSubmit(studentData.username, studentData.code, studentData.newPassword);

			addToast({
				message: "Password was successfully changed.",
				severity: TOAST_SETTINGS.severity.SUCCESS,
			});
		} catch (err) {
			addToast({
				message: "There was an error changing the password.",
				severity: TOAST_SETTINGS.severity.SUCCESS,
			});
		}

		setShowEditPasswordDialog(false);
	};

	const handleAddStudentSubmit = async (studentData) => {
		try {
			const { user } = await Auth.signUp({
				username: studentData.user_name,
				password: studentData.password,
				attributes: {
					email: studentData.email,
				},
			});

			const response = await createNewUser(studentData, user);
			if (response.statusCode === 200) {
				addToast({
					message: "User was successfully created.",
					severity: TOAST_SETTINGS.severity.SUCCESS,
				});
				setRefresh(true);
				setShowCreateDialog(false);
			} else {
				addToast({
					message: "There was an error creating the user.",
					severity: TOAST_SETTINGS.severity.ERROR,
				});
			}
		} catch (error) {
			let start = String(error).split(":")[1];
			let middle = start.split("at")[0];
			addToast({
				message: `Error creating user: ${middle}`,
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
	};

	const handleEditStudentSubmit = async (studentData) => {
		const response = await EditUser(studentData);
		if (response.statusCode === 200) {
			addToast({
				message: "User was successfully edited.",
				severity: TOAST_SETTINGS.severity.SUCCESS,
			});
			setRefresh(true);
		} else {
			addToast({
				message: "There was an error editing the user.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
	};

	const handleViewStudent = (params) => () => {
		setSelectedStudent(params.row);
		setShowStudentInfoDialog(true);
	};

	const handleChangePassword = (params) => () => {
		setSelectedStudent(params.row);
		forgotPassword(params.row.cognito_username);
	};

	const handleConfirmStudent = (params) => () => {
		setSelectedStudent(params.row);
		setShowConfirmDialog(true);
	};

	const handleImpersonate = (params) => () => {
		let temp = {
			state: {
				user: params.row,
			},
		};
		navigate("/startgame", temp);
	};

	useEffect(() => {
		setColumns([
			...student_cols,
			{
				field: "actions",
				type: "actions",
				width: 170,
				getActions: (params) => {
					return [
						<Tooltip title="View Student">
							<GridActionsCellItem
								icon={<AccountCircleIcon />}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
								}}
								label="View Student"
								className="textPrimary"
								onClick={handleViewStudent(params)}
								color="inherit"
							/>
						</Tooltip>,
						<Tooltip title="Impersonate">
							<GridActionsCellItem
								icon={<SupervisorAccountIcon />}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
								}}
								label="Impersonate"
								disabled={Boolean(params.row.games_assigned.length == 0)}
								className="textPrimary"
								onClick={handleImpersonate(params)}
								color="inherit"
							/>
						</Tooltip>,
						<Tooltip title="Change Password">
							<GridActionsCellItem
								icon={<KeyIcon />}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
								}}
								label="Edit Password"
								onClick={handleChangePassword(params)}
								color="inherit"
							/>
						</Tooltip>,
						<Tooltip title="Confirm Student">
							<GridActionsCellItem
								icon={<CheckIcon />}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
									marginRight: "7px",
								}}
								label="Confirm Student"
								className="textPrimary"
								disabled={Boolean(params.row.is_confirmed)}
								onClick={handleConfirmStudent(params)}
								color="inherit"
							/>
						</Tooltip>,
					];
				},
			},
		]);
		const fetchData = async () => {
			const result = await getStudents();

			setData(result);
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const result = await getStudents();

			setData(result);
		};
		if (refresh) {
			fetchData();
			setRefresh(false);
		}
	}, [refresh]);

	function CustomToolbar() {
		const anchorEl = useRef();
		const [open, setOpen] = useState(false);

		const handleClose = async (e) => {
			setOpen(false);
		};

		const handleChange = async (id) => {
			// check to see if the user has already has the game assigned
			let assigned = [];
			data.forEach((user) => {
				// check to see if the user is in the rowSelectionModel
				if (rowSelectionModel.some((row) => user.id === row)) {
					if (user.games_assigned.length == 0) {
						assigned.push(user.id);
					} else if (user.games_assigned.some((game) => game.game_id !== id)) {
						assigned.push(user.id);
					}
				}
			});

			let body = {
				games: [id],
				users: assigned,
			};

			const response = await assignUserGames(body);
			if (response.statusCode === 200) {
				addToast({
					message: "Successfully assigned games.",
					severity: TOAST_SETTINGS.severity.SUCCESS,
				});
				setRowSelectionModel([]);
				setRefresh(true);
			} else {
				addToast({
					message: "There was an error assigning games.",
					severity: TOAST_SETTINGS.severity.ERROR,
				});
			}
		};

		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<div>
					<Tooltip title="Assign Games">
						<IconButton
							ref={anchorEl}
							color="primary"
							component="span"
							disabled={false}
							sx={{ mr: 2 }}
							onClick={() => {
								setOpen((x) => !x);
							}}
						>
							<AssignmentTurnedInOutlinedIcon />{" "}
							<Typography fontSize={13} sx={{ marginLeft: 1 }}>
								ASSIGN GAMES
							</Typography>
						</IconButton>
					</Tooltip>
					<Menu id="basic-menu" anchorEl={anchorEl.current} open={open} onClose={handleClose} transformOrigin={{ horizontal: "left", vertical: "top" }} anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
						<FormControl>
							<FormGroup>
								{games.map((game) => {
									return <MenuItem onClick={() => handleChange(game.game_id)}>{game.game_name}</MenuItem>;
								})}
							</FormGroup>
						</FormControl>
					</Menu>
				</div>
			</GridToolbarContainer>
		);
	}

	return (
		<>
			<Box variant="outlined" sx={{ display: "flex", alignItems: "center", backgroundColor: "white", borderRadius: 2, border: 1, borderColor: "#e0e0e0", alignContent: "center", textAlign: "center" }}>
				<Grid container spacing={2} direction="row">
					<Grid item xs={2}></Grid>
					<Grid item xs={8} sx={{ textAlign: "center" }}>
						<Typography sx={{ fontSize: 16, padding: "18px" }} color="#6d7073" component="div">
							<b>Manage Students</b>
						</Typography>
					</Grid>
					<Grid item xs={2} sx={{ alignSelf: "center" }}>
						<Tooltip title={`Add Student`}>
							<IconButton
								aria-label="add student"
								size="large"
								onClick={() => setShowCreateDialog(true)}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
									float: "right",
									marginRight: "5px",
								}}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item xs={12} sx={{ marginRight: "10px", marginLeft: "10px", marginBottom: "10px", paddingRight: "20px !important", borderWidth: 4, borderColor: "red" }}>
						<DataGrid
							rows={data}
							columns={columns}
							checkboxSelection
							onRowSelectionModelChange={(newRowSelectionModel) => {
								setRowSelectionModel(newRowSelectionModel);
							}}
							columnVisibilityModel={columnVisibilityModel}
							onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
							rowSelectionModel={rowSelectionModel}
							pagination
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[5, 10, 25]}
							density="standard"
							disableVirtualization
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
					</Grid>
				</Grid>
			</Box>
			<AddStudentDialog open={showCreateDialog} handleCancel={() => setShowCreateDialog(false)} handleSubmit={handleAddStudentSubmit} user={navUser}></AddStudentDialog>
			<EditPasswordDialog open={showEditPassword} handleCancel={() => setShowEditPasswordDialog(false)} handleSubmit={handleSubmit} user={selectedStudent}></EditPasswordDialog>
			<StudentInfoDialog open={showStudentInfoDialog} handleSubmit={handleEditStudentSubmit} handleCancel={() => setShowStudentInfoDialog(false)} user={selectedStudent} navUser={navUser}></StudentInfoDialog>
			<ConfirmStudentDialog open={showConfirmDialog} handleCancel={() => setShowConfirmDialog(false)} handleSubmit={(student) => confirmSignUp(student)} user={selectedStudent}></ConfirmStudentDialog>
		</>
	);
}

export default ManageStudents;
