import { API, Amplify, Auth } from "aws-amplify";

Amplify.configure({
	aws_cognito_region: "us-east-2",
	Auth: {
		identityPoolId: "us-east-2:4afb7022-c53b-431a-b5ee-7c2499ce04f5", // REQUIRED - Amazon Cognito Identity Pool ID
		region: "us-east-2", // REQUIRED - Amazon Cognito Region
		userPoolId: "us-east-2_PHjtSQXtJ",
		userPoolWebClientId: "7d3fniusemp2e47u8a7mhkti4t",
	},
	API: {
		endpoints: [
			{
				name: "apiGateway",
				endpoint: "https://zfmaj09w42.execute-api.us-east-2.amazonaws.com/dev",
			},
		],
	},
});

let refData = [];

export async function getRefData() {
	const apiName = "apiGateway";
	const path = `/refdata/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
	};
	let data = await API.get(apiName, path, myInit);
	refData = JSON.parse(data.body);
	console.log(refData);
}

export function setSchools() {
	let schools = [];
	if (refData.schools != undefined && refData.schools.length > 0) {
		refData.schools.forEach((school) => {
			schools.push(school);
		});
	}

	return schools;
}

export function setGames() {
	let games = [];
	if (refData.games != undefined && refData.games.length > 0) {
		refData.games.forEach((game) => {
			games.push(game);
		});
	}

	return games;
}

export function setCountries() {
	let countries = [];
	if (refData.countries != undefined && refData.countries.length > 0) {
		refData.countries.forEach((country) => {
			countries.push(country);
		});
	}

	return countries;
}

export function setSchoolTypes() {
	let types = [];
	if (refData.school_types != undefined && refData.school_types.length > 0) {
		refData.school_types.forEach((type) => {
			types.push(type);
		});
	}

	return types;
}

export function setGameTypes() {
	let types = [];
	if (refData.game_types != undefined && refData.game_types.length > 0) {
		refData.game_types.forEach((type) => {
			types.push(type);
		});
	}

	return types;
}
