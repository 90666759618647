import LogoutIcon from "@mui/icons-material/Logout";
import PatternIcon from "@mui/icons-material/Pattern";
import SchoolIcon from "@mui/icons-material/School";
import { Avatar, Box, Button, Divider, Drawer, ListItemButton, Toolbar, Tooltip } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
//import logo from "../images/mainIcon.png";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Auth } from "aws-amplify";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { getDataExport } from "../api/export";
import LoadSpinner from "../config/loading";
import logo from "../images/logo.png";
import Dashboard from "./dashboard";
import Pattern from "./pattern";
import StartGame from "./startgame";

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function NavBar({ user, signOut }) {
	const [open, setOpen] = useState(false);
	const [drawerIndex, setDrawerIndex] = useState(1);
	const [appUser, setAppUser] = useState(user);
	const navigate = useNavigate();

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const handleDrawerClick = (index, text) => {
		setOpen(false);
		setDrawerIndex(index);
	};

	const handleSignOut = async () => {
		navigate("/");
		await Auth.signOut({ global: true });
	};

	useEffect(() => {
		setAppUser(user);
	}, [user]);

	function stringAvatar(name, height, width, fontsize) {
		let init = "";

		if (name?.search(" ") == -1) {
			init = name?.substring(0, 1);
		} else {
			init = `${name?.split(" ")[0][0]}${name.split(" ")[1][0]}`;
		}

		return {
			style: {
				backgroundColor: stringToColor(name),
				height: height,
				width: width,
				fontSize: fontsize,
			},
			children: init.toUpperCase(),
		};
	}

	function stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */
		return color;
	}

	const handleExportData = async () => {
		const response = await getDataExport();
		let parsed = JSON.parse(response.body);

		const blob = new Blob([JSON.stringify(parsed)], { type: "text/json" });
		const link = document.createElement("a");

		link.download = "jsonExport";
		link.href = window.URL.createObjectURL(blob);
		link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

		const evt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true,
		});

		link.dispatchEvent(evt);
		link.remove();
	};

	return (
		<Box sx={{ display: "flex" }}>
			<AppBar position="fixed" open={open} sx={{ boxShadow: "none", backgroundColor: "#462d8e" }}>
				<Toolbar>
					<Button sx={{ mr: 2, p: "6px", display: { xs: "flex", md: "flex" }, color: "white", "&:hover": { backgroundColor: "#462d8e", color: "white" }, borderRadius: "50%" }}>
						<img src={logo} loading="lazy" height={"40px"} />
					</Button>
					<Typography variant="h5" component="div" sx={{ display: { xs: "flex", md: "flex" }, color: "white" }}>
						Pattern Game
					</Typography>
					<Box sx={{ flexGrow: 1 }}></Box>

					<Box>
						<IconButton onClick={handleDrawerOpen} size="large" sx={{ color: "white", "&:hover": { backgroundColor: "#462d8e", color: "white" } }}>
							<Avatar {...stringAvatar(`${user?.first_name} ${user?.last_name}`, 40, 40, "1rem")} />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer variant="temporary" open={open} anchor="right">
				<List sx={{ marginTop: "70px", width: "300px" }}>
					<ListItemButton sx={{ paddingTop: "5px", paddingBottom: "5px", textAlign: "center" }} key="rachau87@gmail.com">
						<ListItemText primary={`${user?.first_name} ${user?.last_name}`} />
					</ListItemButton>
					{/*{user?.is_admin && (
            <Link to="admin" style={{ textDecoration: "none", color: "#1d1d1d" }}>
              <ListItemButton selected={drawerIndex === 3} onClick={() => handleDrawerClick(3)} sx={{ paddingTop: "5px", paddingBottom: "5px" }} key="Admin">
                <ListItemIcon>
                  <Tooltip title="Admin">
                    <SettingsIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Administration" />
              </ListItemButton>
            </Link>
          )} */}
					<ListItemButton onClick={handleSignOut} sx={{ paddingTop: "5px", paddingBottom: "5px" }} key="Log Out">
						<ListItemIcon>
							<Tooltip title="Log Out">
								<LogoutIcon />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Log Out" />
					</ListItemButton>
					<Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
					<Link to="dashboard" style={{ textDecoration: "none", color: "#1d1d1d" }}>
						<ListItemButton selected={drawerIndex === 1} onClick={() => handleDrawerClick(1)} sx={{ paddingTop: "5px", paddingBottom: "5px" }} key="Teacher Dashboard">
							<ListItemIcon>
								<Tooltip title="Teacher Dashboard">
									<SchoolIcon />
								</Tooltip>
							</ListItemIcon>
							<ListItemText primary="Teacher Dashboard" />
						</ListItemButton>
					</Link>
					<Link to="startgame" style={{ textDecoration: "none", color: "#1d1d1d" }}>
						<ListItemButton selected={drawerIndex === 2} onClick={() => handleDrawerClick(2)} sx={{ paddingTop: "5px", paddingBottom: "5px" }} key="Pattern Game">
							<ListItemIcon>
								<Tooltip title="Pattern Game">
									<PatternIcon />
								</Tooltip>
							</ListItemIcon>
							<ListItemText primary="Pattern Game" />
						</ListItemButton>
					</Link>
					<ListItemButton selected={drawerIndex === 3} onClick={() => handleExportData()} sx={{ paddingTop: "5px", paddingBottom: "5px" }} key="Pattern Game">
						<ListItemIcon>
							<Tooltip title="Export Data">
								<FileDownloadIcon />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Export Data" />
					</ListItemButton>
				</List>
			</Drawer>

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					backgroundColor: "#b9b9b940",
					minHeight: "95vh",
					maxHeight: "max-content",
					borderRadius: 4,
					position: "relative",
					top: "64px",
				}}
			>
				<Box>
					<Routes>
						<Route path="/" element={appUser ? appUser?.is_teacher === 1 ? <Dashboard user={appUser} handleDrawerClick={() => handleDrawerClick(1)} /> : <StartGame user={appUser} /> : <LoadSpinner />} />
						<Route path="dashboard" element={<Dashboard user={appUser} navOpen={open} handleDrawerClick={() => handleDrawerClick(1)} />} />
						<Route path="pattern" element={<Pattern user={appUser} handleDrawerClick={() => handleDrawerClick(2)} />} />
						<Route path="startgame" element={<StartGame user={appUser} />} />
						<Route path="admin" element={<StartGame user={appUser} />} />
					</Routes>
				</Box>
			</Box>
		</Box>
	);
}
