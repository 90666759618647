import { Box, Grid, ImageList, ImageListItem, ImageListItemBar, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Amplify, Storage } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { getResultsById } from "../api/analytics";
import { setGames } from "../api/refdataApi";
import { icons } from "../config/constants";
import SearchBar from "./searchBar";

let tempIcons = [...icons];

Amplify.configure({
  aws_cognito_region: "us-west-2",
  Storage: {
    AWSS3: {
      bucket: "abcpattern7840fd48a68b49eb926c12323e49d94f82232-abctestpat", //REQUIRED -  Amazon S3 bucket name
      region: "us-west-2", //OPTIONAL -  Amazon service region
    },
  },
});

async function getById(body) {
  const data = await getResultsById(body);
  let parsed = JSON.parse(data.body);
  return parsed;
}

function GetSignedUrl({ url, name }) {
  const [signed, setSigned] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const result = await Storage.get(url);
      setSigned(result);
    };
    fetchData();
  }, [url]);

  return <img src={signed} loading="lazy" height={"148px"} width={"148px"} alt={name} />;
}

function Analytics({ user }) {
  const [images, setImages] = useState([]);
  const [filterdImages, setFilterdImages] = useState([]);
  const games = useMemo(() => setGames());
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const img = await getById({ game_id: 1 });
      let fistGame = games[0];
      setSelectedItem(fistGame);
      setSelectedIndex(fistGame.game_id);
      setImages(img);
      setFilterdImages(img);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // needs to be the game id
      const result = await getById({ game_id: selectedItem.game_id });
      setSelectedIndex(selectedItem.game_id);
      setImages(result);
      setFilterdImages(result);
    };
    if (selectedItem) {
      fetchData();
    }
  }, [selectedItem]);

  const requestSearch = (searchedVal) => {
    let val = String(searchedVal);
    if (val != "") {
      const filterd = images.filter((o) => Object.keys(o).some((k) => JSON.stringify(o[k]).toLowerCase().includes(val.toLowerCase())));
      setFilterdImages(filterd);
    } else {
      setFilterdImages(images);
    }
  };

  const handleListItemClick = (item, id) => {
    setSelectedItem(item);
  };

  return (
    <>
      <Box variant="outlined" sx={{ display: "flex", alignItems: "center", backgroundColor: "white", borderRadius: 2, border: 1, borderColor: "#e0e0e0" }}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={2}></Grid>
          <Grid item xs={8} sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: 16, padding: "18px" }} color="#6d7073" component="div">
              <b>Data Analytics</b>
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ alignSelf: "center" }}></Grid>
          <Grid item container sx={{ margin: "10px" }}>
            <Grid item xs={2}>
              <List component="nav" aria-label="games">
                <ListItem sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Game List</b>
                  </Typography>
                </ListItem>
                {games.map((item) => {
                  return (
                    <ListItemButton sx={{ borderRadius: 3, textAlign: "center", marginLeft: "20px", marginRight: "20px", marginBottom: "10px" }} selected={selectedIndex === item.game_id} onClick={() => handleListItemClick(item)}>
                      <ListItemText primary={item.game_name} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={10}>
              <SearchBar style={{ marginRight: "4rem", borderRadius: "0.25rem", border: "1px solid rgba(224, 224, 224, 1)", padding: 5 }} onChange={requestSearch} />
              <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "white", marginLeft: "10px" }}>
                <Grid item container sx={{ marginTop: "20px", overflowY: "scroll", height: "64vh" }}>
                  {filterdImages.map((image) => {
                    // Create items array
                    let items = Object.keys(image.results).map(function (key) {
                      return { iconid: key, count: image.results[key] };
                    });
                    let sorted = items.sort((a, b) => b.count - a.count);
                    return (
                      <>
                        <Grid item container sx={{ border: "1px solid #e0e0e0", borderRadius: 2, marginTop: "5px", height: "400px" }}>
                          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <ImageList sx={{ width: 150, height: 150, borderRadius: "5px", border: "1px solid #a9ada9" }} cols={1}>
                              <ImageListItem sx={{ display: "flex", justifyContent: "center" }}>
                                <GetSignedUrl url={image.image_url} name={image.image_name} />

                                <ImageListItemBar title={image.image_name} />
                              </ImageListItem>
                            </ImageList>
                          </Grid>

                          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                            <Typography>Correct Patterns(s):</Typography>
                          </Grid>
                          <Grid item xs={9} sx={{ display: "flex", justifyContent: "center" }}>
                            <ImageList sx={{ width: 575, height: { sm: 60, md: 70, lg: 80 }, backgroundColor: "#dcdcdc", marginTop: "0px", border: "1px solid #e4e4e4", borderRadius: 2, padding: "5px", overflow: "hidden" }} cols={9}>
                              {image.image_scoring.map((item) => {
                                let key = Object.keys(item);
                                let value = item[key];
                                let temp = tempIcons.find((t) => {
                                  return t.id == Number(key);
                                });

                                return (
                                  <ImageListItem key={temp.id} sx={{ width: { sm: 50, md: 60, lg: 70 }, backgroundColor: "#fff", marginLeft: "5px", minWidth: "50px", height: { sm: 50, md: 60, lg: 70 }, padding: "7px", border: "1px solid #a9ada9", borderRadius: 2 }}>
                                    <img src={temp.url} loading="lazy" alt={temp.name} />
                                  </ImageListItem>
                                );
                              })}
                            </ImageList>
                          </Grid>
                          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                            <Typography>Top Selected Patterns(s):</Typography>
                          </Grid>
                          <Grid item xs={9} sx={{ display: "flex", justifyContent: "center" }}>
                            <ImageList sx={{ width: 575, height: { sm: 60, md: 70, lg: 80 }, backgroundColor: "#dcdcdc", marginTop: "0px", border: "1px solid #e4e4e4", borderRadius: 2, padding: "5px", overflow: "hidden" }} cols={9}>
                              {sorted.slice(0, 5).map((item) => {
                                let key = item.iconid;
                                let value = item.count;
                                let temp = tempIcons.find((t) => {
                                  return t.id == Number(key);
                                });

                                return (
                                  <ImageListItem key={temp.id} sx={{ width: { sm: 50, md: 60, lg: 70 }, backgroundColor: "#fff", marginLeft: "5px", minWidth: "50px", height: { sm: 50, md: 60, lg: 70 }, padding: "7px", border: "1px solid #a9ada9", borderRadius: 2 }}>
                                    <img src={temp.url} loading="lazy" alt={temp.name} />
                                    <ImageListItemBar title={value} sx={{ height: 20, borderRadius: 2, textAlign: "center" }} />
                                  </ImageListItem>
                                );
                              })}
                            </ImageList>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Analytics;
