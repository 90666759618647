import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton } from "@mui/material";

export const schoolCols = [
	{
		headerName: "School",
		field: "school_name",
		width: 200,
	},
	{
		headerName: "Type",
		field: "schoolType",
		width: 150,
		valueGetter: (params) => {
			return `${params.row.school_type[`school_type_name`]}`;
		},
	},
	{
		headerName: "Coed",
		field: "is_coed",
		width: 150,
		valueGetter: (params) => {
			let retVal = params.row.is_coed;
			return retVal ? "True" : "False";
		},

		align: "center",
		renderCell: (params) => {
			let bol = params.row.is_coed;
			return (
				<Box>
					{bol ? (
						<IconButton
							size="small"
							sx={{
								color: "#6d7073",
							}}
						>
							<CheckIcon />
						</IconButton>
					) : (
						<IconButton
							size="small"
							sx={{
								color: "#6d7073",
							}}
						>
							<ClearIcon />
						</IconButton>
					)}
				</Box>
			);
		},
	},
	{
		headerName: "Country",
		field: "school_country_id",
		width: 150,
		valueGetter: (params) => {
			return `${params.row.school_country[`nicename`]}`;
		},
	},
	{
		headerName: "Zipcode",
		field: "zipcode",
		width: 200,
	},
	{
		headerName: "Birth Year",
		field: "birth_year",
		width: 200,
	},
];
