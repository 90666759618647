import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { setSchools } from "../api/refdataApi";

export default function AddStudentDialog({ open, handleCancel, handleSubmit, user }) {
	const [studentData, setStudentData] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [isTeacher, setIsTeacher] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [schoolId, setSchoolId] = useState(99);
	const [schools, setSchool] = useState(setSchools());

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		if (open) {
			setStudentData({});
			setIsAdmin(false);
			setIsTeacher(false);
		}
	}, [open]);

	useEffect(() => {
		if (open) {
			setSchool(setSchools());
		}
	}, [setSchools()]);

	const handleClose = (update) => {
		if (update) {
			let student = {
				...studentData,
				is_admin: isAdmin,
				is_teacher: isTeacher,
				school_id: schoolId,
			};
			handleSubmit(student);
		} else {
			handleCancel();
			setStudentData({});
		}
	};

	const handleStringChange = (event) => {
		event.persist();
		setStudentData({
			...studentData,
			[event.target.name]: String(event.target.value),
		});
	};

	const handleNumberChange = (event) => {
		event.persist();
		setStudentData({
			...studentData,
			[event.target.name]: Number(event.target.value),
		});
	};

	const handleSchoolChange = (event) => {
		setStudentData({
			...studentData,
			school_id: Number(event.target.value),
		});
		setSchoolId(Number(event.target.value));
	};

	return (
		<Box sx={{ padding: "15px" }}>
			<Dialog open={open}>
				<DialogTitle>
					<Typography variant="h6" align="center">
						{user?.is_admin ? "Add User" : "Add Student"}
					</Typography>
				</DialogTitle>
				<DialogContent align="left">
					<Grid container spacing={2} sx={{ marginTop: "5px" }}>
						{user?.is_admin && (
							<>
								<Grid item xs={6} sx={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
									<FormControlLabel required control={<Checkbox checked={Boolean(isTeacher)} onChange={() => setIsTeacher(!isTeacher)} />} label="Teacher Account" />
								</Grid>
								<Grid item xs={6} sx={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
									<FormControlLabel required control={<Checkbox checked={Boolean(isAdmin)} onChange={() => setIsAdmin(!isAdmin)} />} label="Admin Account" />
								</Grid>
							</>
						)}

						<Grid item xs={6}>
							<TextField name="user_name" margin="dense" fullWidth label="User Name" type="string" variant="outlined" onChange={handleStringChange} required />
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
								<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
								<OutlinedInput
									id="outlined-adornment-password"
									type={showPassword ? "text" : "password"}
									name="password"
									onChange={handleStringChange}
									endAdornment={
										<InputAdornment position="end">
											<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label="Password"
								/>
							</FormControl>
						</Grid>

						<Grid item xs={6}>
							<TextField name="first_name" margin="dense" fullWidth label="First Name" type="string" variant="outlined" onChange={handleStringChange} required />
						</Grid>
						<Grid item xs={6}>
							<TextField name="last_name" margin="dense" fullWidth label="Last Name" type="string" variant="outlined" onChange={handleStringChange} required />
						</Grid>
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth sx={{ mt: "8px" }}>
								<InputLabel id="contact-type-label">School *</InputLabel>
								<Select name="school_id" value={schoolId} onChange={handleSchoolChange} fullWidth={true} label="School *">
									{schools.map((school) => {
										return (
											<MenuItem key={school.school_id} value={school.school_id}>
												{school.school_name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={6}>
							<TextField name="grade" margin="dense" fullWidth label="Grade" type="number" variant="outlined" onChange={handleNumberChange} required />
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ mt: "8px" }}>
						<TextField name="email" margin="dense" fullWidth label="Email" type="string" variant="outlined" onChange={handleStringChange} required />
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={() => handleClose(true)} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
