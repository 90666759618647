import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { setCountries, setSchoolTypes } from "../api/refdataApi";

export default function SchoolDialog({ open, handleCancel, handleSubmit, user, school }) {
	let isEdit = school !== null ? true : false;
	const [schoolData, setSchoolData] = useState({});
	const [schoolType, setSchoolType] = useState(1);
	const [isCoed, setIsCoed] = useState(1);
	const [country, setCountry] = useState(226);

	useEffect(() => {
		if (open) {
			if (isEdit) {
				let temp = { ...school };
				temp.school_country_id = school?.school_country?.country_id;
				temp.school_type_id = school?.school_type?.school_type_id;
				console.log(temp);
				setSchoolData(temp);
				setCountry(school?.school_country?.country_id);
				setSchoolType(school?.school_type?.school_type_id);
				setIsCoed(school?.is_coed);
			} else {
				setSchoolData({});
			}
		}
	}, [open]);

	const handleClose = (update) => {
		if (update) {
			let school = {
				...schoolData,
				is_coed: Boolean(isCoed) === true ? 1 : 0,
			};
			handleSubmit(school);
		} else {
			handleCancel();
		}
		setSchoolData({});
		setCountry(226);
		setIsCoed(1);
		setSchoolType(1);
	};

	const handleStringChange = (event) => {
		event.persist();
		setSchoolData({
			...schoolData,
			[event.target.name]: String(event.target.value),
		});
	};

	const handleNumberChange = (event) => {
		event.persist();
		setSchoolData({
			...schoolData,
			[event.target.name]: Number(event.target.value),
		});
	};

	const handleSchoolTypeChange = (event) => {
		setSchoolData({
			...schoolData,
			school_type_id: Number(event.target.value),
		});
		setSchoolType(Number(event.target.value));
	};

	const handleCountryChange = (event) => {
		setSchoolData({
			...schoolData,
			school_country_id: Number(event.target.value),
		});
		setCountry(Number(event.target.value));
	};

	const handleCoedChange = (event) => {
		setIsCoed(Boolean(!isCoed));
	};

	return (
		<Box sx={{ padding: "15px" }}>
			<Dialog open={open}>
				<DialogTitle>
					<Typography variant="h6" align="center">
						{isEdit ? "Edit School" : "Add School"}
					</Typography>
				</DialogTitle>
				<DialogContent align="left">
					<Grid container spacing={2} sx={{ marginTop: "5px" }}>
						<Grid item xs={6}>
							<TextField name="school_name" defaultValue={school?.school_name} margin="dense" fullWidth label="School Name" type="string" variant="outlined" onChange={handleStringChange} required />
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								alignSelf: "center",
								justifyContent: "center",
								display: "flex",
							}}
						>
							<FormControlLabel required control={<Checkbox checked={Boolean(isCoed)} onChange={handleCoedChange} />} label="Co-ed" />
						</Grid>
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth sx={{ mt: "8px" }}>
								<InputLabel id="contact-type-label">School Type *</InputLabel>
								<Select name="school_type_id" value={schoolType} onChange={handleSchoolTypeChange} fullWidth={true} label="School Type *">
									{setSchoolTypes().map((type) => {
										return (
											<MenuItem key={type.school_type_id} value={type.school_type_id}>
												{type.school_type_name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth sx={{ mt: "8px" }}>
								<InputLabel id="contact-type-label">Country *</InputLabel>
								<Select name="country_id" value={country} onChange={handleCountryChange} fullWidth={true} label="Country *">
									{setCountries().map((country) => {
										return (
											<MenuItem key={country.country_id} value={country.country_id}>
												{country.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<TextField name="zipcode" margin="dense" defaultValue={school?.zipcode} fullWidth label="Zipcode" type="string" variant="outlined" onChange={handleStringChange} required />
						</Grid>
						<Grid item xs={6}>
							<TextField name="birth_year" margin="dense" defaultValue={school?.birth_year} fullWidth label="Birth Year" type="number" variant="outlined" onChange={handleNumberChange} required />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={() => handleClose(true)} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
