import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card, Grid, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataAnalytics from "./dataAnalytics";
import ManageGames from "./manageGames";
import ManageSchools from "./manageSchools";
import ManageStudents from "./manageStudents";

function Dashboard({ handleDrawerClick, user }) {
	const [tabIndex, setTabIndex] = useState("1");
	const navigate = useNavigate();

	useEffect(() => {
		console.log(user);
		if (user.is_teacher === 0) {
			let temp = {
				state: {
					user: user,
				},
			};
			navigate("/startgame", temp);
		}
		handleDrawerClick();
	}, [user]);

	const handleChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	return (
		<>
			<Grid container spacing={2} direction="row">
				<Grid item xs={2}></Grid>
				<Grid item xs={8}>
					<Grid container spacing={2} direction="row">
						<Card style={{ width: "100%" }}>
							<TabContext value={tabIndex}>
								<TabList onChange={handleChange}>
									<Tab label="Manage Students" value="1" />
									<Tab label="Manage Schools" value="2" />
									<Tab label="Manage Games" value="3" />
									<Tab label="Data Analytics" value="4" />
								</TabList>
								<TabPanel value="1" sx={{ paddingTop: 0, paddingBottom: 0, paddingTop: "10px" }}>
									<ManageStudents navUser={user} />
								</TabPanel>
								<TabPanel value="2" sx={{ paddingTop: 0, paddingBottom: 0, paddingTop: "10px" }}>
									<ManageSchools navUser={user} />
								</TabPanel>
								<TabPanel value="3" sx={{ paddingTop: 0, paddingBottom: 0, paddingTop: "10px" }}>
									<ManageGames />
								</TabPanel>
								<TabPanel value="4" sx={{ paddingTop: 0, paddingBottom: "10px", paddingTop: "10px" }}>
									<DataAnalytics />
								</TabPanel>
							</TabContext>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={2}></Grid>
			</Grid>
		</>
	);
}

export default Dashboard;
