import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListItemButton, ListItemIcon, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { IconCats, icons } from "../config/constants";

export default function AddGameDialog({ open, handleCancel, handleSubmit, user, game }) {
  const [imageData, setImageData] = useState({ image_scoring: [] });

  let iconNames = IconCats.flatMap((cat) => cat.name);
  iconNames.unshift(" ");

  let temp = [...icons];
  let catted = { 1: [], 2: [], 3: [], 4: [] };
  temp.forEach((icon) => {
    catted[icon.group.id].push(icon);
  });

  const fileInput = useRef();
  let properties = ["image_name", "image_scoring", "url"];

  const handleClose = (update) => {
    if (update) {
      let image = {
        ...imageData,
        game_id: game.game_id,
      };
      handleSubmit(image);
    } else {
      handleCancel();
    }
    setImageData({ image_scoring: [] });
  };

  const handleStringChange = (event) => {
    event.persist();
    setImageData({
      ...imageData,
      [event.target.name]: String(event.target.value),
    });
  };

  const handleScoringChange = (event, id) => {
    event.persist();
    event.preventDefault();
    let temp = { ...imageData };
    if (imageData.image_scoring.length == 0) {
      temp.image_scoring.push({ [id]: Number(event.target.value) });
    } else {
      for (let i = 0; i < imageData.image_scoring.length; i++) {
        if (imageData.image_scoring[i][id] !== undefined) {
          temp.image_scoring[i][id] = Number(event.target.value);
          break;
        }
        if (i == imageData.image_scoring.length - 1) {
          temp.image_scoring.push({ [id]: Number(event.target.value) });
          break;
        }
      }
    }
    setImageData(temp);
  };

  let meetsRequiremnts = false;
  for (let p of properties) {
    if (!Object.hasOwn(imageData, p)) {
      meetsRequiremnts = false;
      break;
    }
    meetsRequiremnts = true;
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      let temp = {
        ...imageData,
      };
      temp.url = reader.result;
      temp.file = file;
      temp.image_url = file.name;
      setImageData(temp);
    };
    reader.readAsDataURL(file);
    e.target.value = null;
  };

  function updateClick() {
    fileInput.current.click();
  }

  const checkScoring = (icon) => {
    let v = 0;
    for (let i = 0; i < imageData.image_scoring.length; i++) {
      if (imageData.image_scoring[i][icon.id] !== undefined) {
        v = imageData.image_scoring[i][icon.id];
        break;
      }
    }

    return v;
  };

  const ListItemCustom = ({ item }) => {
    let html = [];

    // TODO: needs to be updated for icon cats
    for (let i = 1; i < 5; i++) {
      let iconRows = catted[i].sort((a, b) => a.sortOrder - b.sortOrder);

      html.push(
        <>
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
            <Typography sx={{ fontSize: 14, padding: "18px" }} color="#6d7073" component="div">
              <b>{iconNames[i]}</b>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Stack direction="row">
              {iconRows.map((icon) => {
                return (
                  <>
                    <Stack>
                      <ListItemButton sx={{ display: "flex", justifyContent: "center", width: 70, height: 70, border: "1px solid #a9ada9", borderRadius: 2, backgroundColor: "#e0e0e0ad", margin: "5px" }}>
                        <ListItemIcon sx={{ width: { sm: 50, md: 60, lg: 70 }, minWidth: "50px", height: { sm: 50, md: 60, lg: 70 }, padding: "7px" }}>
                          <img src={icon.url} loading="lazy" alt={icon.name} />
                        </ListItemIcon>
                      </ListItemButton>
                      <TextField name="score" sx={{ width: "60px", marginLeft: "8px", textAlignLast: "center" }} onChange={(e) => handleScoringChange(e, icon.id, item)} value={checkScoring(icon)} margin="dense" type="number" variant="standard" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
          </Grid>
        </>
      );
    }
    return html;
  };

  return (
    <Box sx={{ padding: "15px" }}>
      <Dialog open={open} maxWidth="800px">
        <DialogTitle>
          <Typography variant="h6" align="center">
            Add Game Image for {game?.game_name}
          </Typography>
        </DialogTitle>
        <DialogContent align="left" sx={{ maxWidth: "950px", minWidth: "950px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <input
                type="file"
                onChange={(e) => {
                  handleUpdate(e);
                }}
                ref={(el) => {
                  fileInput.current = el;
                }}
                hidden
              />
              <ImageList sx={{ width: 250, height: 250, borderRadius: "5px", border: "1px solid #a9ada9" }} cols={1}>
                <ImageListItem>
                  <img src={imageData.url} loading="lazy" />
                  <ImageListItemBar
                    title={"Add Image"}
                    actionIcon={
                      <IconButton sx={{ color: "#ffff" }} onClick={(e) => updateClick(e)}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid item xs={5} sx={{ alignSelf: "center", textAlign: "end", marginRight: "20px" }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <b>Image Name:</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField name="image_name" margin="dense" fullWidth label="Image Name" type="string" variant="outlined" onChange={handleStringChange} required />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item container>
              <ListItemCustom />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" disabled={!meetsRequiremnts}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
