import { Button, Card, Grid, ImageList, ImageListItem, ListItemButton, ListItemIcon, Stack } from "@mui/material";
import { Amplify, Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addResult, finishGame } from "../api/gamesApi";
import { TOAST_SETTINGS, icons } from "../config/constants";
import useToastContext from "../config/useToastContext";

Amplify.configure({
	aws_cognito_region: "us-east-2",
	Storage: {
		AWSS3: {
			bucket: "edwebapp8a95d58d22d94fe98ac018e682e9ab48123107-dev", //REQUIRED -  Amazon S3 bucket name
			region: "us-east-2", //OPTIONAL -  Amazon service region
		},
	},
});

async function addResultAPI(body) {
	const data = await addResult(body);
	let parsed = JSON.parse(data.body);
	return parsed;
}

async function finishGameAPI(body) {
	const data = await finishGame(body);
	let parsed = JSON.parse(data.body);
	return parsed;
}

let temp = [...icons];
let catted = { 1: [], 2: [], 3: [], 4: [] };
temp.forEach((icon) => {
	catted[icon.group.id].push(icon);
});

const ListItemCustom = ({ selected, handleParrentSelected }) => {
	let html = [];
	// because there are negatives now to be able to show when they deselecteed
	// need to update log for if something is selected
	// first need to make all value positive
	for (let i = 1; i < 5; i++) {
		let iconRows = catted[i].sort((a, b) => a.sortOrder - b.sortOrder);
		html.push(
			<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
				<Stack direction="row">
					{iconRows.map((icon) => {
						let positiveSelected = selected.map((item) => Math.abs(item));
						let count = positiveSelected.reduce((a, v) => (v === icon.id ? a + 1 : a), 0);
						return (
							<ListItemButton
								onClick={() => handleParrentSelected(icon.id, icon)}
								selected={count % 2 === 0 ? false : true}
								sx={{ display: "flex", justifyContent: "center", width: { sm: 55, md: 65, lg: 75 }, height: { sm: 55, md: 65, lg: 75 }, border: "1px solid #a9ada9", borderRadius: 2, backgroundColor: "#FFF", margin: "5px" }}
							>
								<ListItemIcon sx={{ width: { sm: 55, md: 65, lg: 75 }, minWidth: "55px", height: { sm: 55, md: 65, lg: 75 }, padding: "3px", alignSelf: "center", display: "flex", justifyContent: "center" }}>
									<img style={{ width: "95%", height: "95%", alignSelf: "center" }} src={icon.url} alt="Click to return to choose" />
								</ListItemIcon>
							</ListItemButton>
						);
					})}
				</Stack>
			</Grid>
		);
	}
	return html;
};

function Pattern({ handleDrawerClick }) {
	const { state } = useLocation();
	const question_amount = state.game.question_amount;
	const game_id = state.game.game_id;
	const imagesArray = state.game.images;
	const user = state.user;
	const [selected, setSelected] = useState([]);
	const [selectedIcons, setSelectedIcons] = useState([]);
	const [amountSelected, setAmountSelected] = useState(7);
	const [currentImage, setCurrentImage] = useState(imagesArray[0]);
	const [imgIndex, setImgIndex] = useState(0);
	// const [gameResults, setGameResults] = useState([]);
	const addToast = useToastContext();
	const [img, setImg] = useState(null);
	const [currentTime, setCurrentTime] = useState(new Date().getTime());
	const [timeArray, setTimeArray] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		handleDrawerClick();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			let signedURL = await Storage.get(currentImage?.url);
			setImg(signedURL);
		};
		fetchData();
	}, [currentImage]);

	const handleParrentSelected = (index, icon) => {
		setCurrentTime(new Date().getTime());
		let timeBetweenClicks = Math.floor(Math.abs((currentTime - new Date().getTime()) / 1000));
		let tempA = [...timeArray, timeBetweenClicks];
		setTimeArray(tempA);
		// this means that its already been selected
		// have to remove from the selected icons
		// but retain for the true button click
		if (selected.some((item) => item === index)) {
			let temp = [...selected];
			/*selected.forEach((item) => {
				if (item !== index) {
					temp.push(item);
				}
			});*/
			let positiveSelected = selected.map((item) => Math.abs(item));
			let count = positiveSelected.reduce((a, v) => (v === index ? a + 1 : a), 0);
			temp.push(count % 2 === 0 ? index : -index);
			let tIcons = [];
			selectedIcons.forEach((item) => {
				if (item.id !== index) {
					tIcons.push(item);
				}
			});
			setSelectedIcons(tIcons);
			setSelected(temp);
		} else {
			if (selectedIcons.length !== amountSelected) {
				setSelected([...selected, index]);
				setSelectedIcons([...selectedIcons, icon]);
			} else {
				addToast({
					message: "You have reached the maximum allowed selected, please remove a selection before you add another.",
					severity: TOAST_SETTINGS.severity.WARNING,
				});
			}
		}
	};

	const changeImage = async () => {
		if (selected.length === 0) {
			addToast({
				message: "You must enter one selection before continuing.",
				severity: TOAST_SETTINGS.severity.WARNING,
			});
		} else {
			let parsed = JSON.parse(currentImage.scoring);
			let selected_parsed = [];
			selected.map((item) => {
				let value = 0;
				parsed.map((par) => {
					if (par[item]) {
						value = par[item];
					}

					if (item < 0) {
						value = -1;
					}
				});
				selected_parsed.push({ [Math.abs(item)]: value });
			});

			let tempBody = {
				game_taken_id: currentImage.games_id,
				image_id: currentImage.id,
				click_time: timeArray,
				correct_icons: parsed,
				selected_icons: selected_parsed,
			};
			await addResultAPI(tempBody);

			//setGameResults([...gameResults, { id: currentImage.id, selected: selected }]);
			setSelected([]);
			setSelectedIcons([]);
			setTimeArray([]);
			let ind = imgIndex + 1;
			setImgIndex(ind);

			if (imgIndex !== question_amount - 1) {
				setCurrentImage(imagesArray[ind]);
			} else {
				let tempBody = {
					user_id: user.id,
					game_id: game_id,
				};

				await finishGameAPI(tempBody);

				let tempUser = {
					...user,
					games_assigned: [],
				};
				let temp = {
					state: {
						user: tempUser,
					},
				};

				navigate("/startgame", temp);
				addToast({
					message: "You have completed the game",
					severity: TOAST_SETTINGS.severity.SUCCESS,
				});
			}
		}
	};

	return (
		<>
			<Grid container spacing={2} direction="row">
				<Grid item xs={2} sm={1}></Grid>
				<Grid item xs={8} sm={10}>
					<Grid container spacing={2} direction="row" sx={{ marginLeft: "0px !important" }}>
						<Card style={{ width: "100%" }}>
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
								<ImageList sx={{ width: { sm: 375, md: 425, lg: 425 }, height: { sm: 375, md: 425, lg: 425 }, borderRadius: 2, border: "1px solid #a9ada9" }} cols={1}>
									<ImageListItem>
										<img src={img} alt={currentImage?.name} loading="eager" />
									</ImageListItem>
								</ImageList>
							</Grid>
							<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
								<ImageList
									sx={{
										width: { sm: selectedIcons.length > 0 ? selectedIcons.length * 65 + 10 : 0, md: selectedIcons.length > 0 ? selectedIcons.length * 75 + 10 : 0, lg: selectedIcons.length > 0 ? selectedIcons.length * 85 + 10 : 0 },
										height: { sm: 65, md: 75, lg: 85 },
										backgroundColor: selectedIcons.length > 0 ? "#6967673b" : "#FFF",
										marginTop: "0px",
										border: selectedIcons.length > 0 ? "1px solid #6967673b" : "#FFF",
										borderRadius: 2,
										padding: "5px",
										overflow: "hidden",
									}}
									cols={9}
								>
									{selectedIcons.map((item, index) => {
										return (
											<ImageListItem key={item.id} sx={{ width: { sm: 55, md: 65, lg: 75 }, backgroundColor: "#fff", marginLeft: "5px", minWidth: "50px", height: { sm: 55, md: 65, lg: 75 }, padding: "3px", border: "1px solid #a9ada9", borderRadius: 2 }}>
												<img src={item.url} loading="lazy" />
											</ImageListItem>
										);
									})}
								</ImageList>
							</Grid>

							<ListItemCustom selected={selected} handleParrentSelected={handleParrentSelected} />

							<Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginBottom: "15px", marginTop: "15px" }}>
								<Button onClick={() => changeImage()} sx={{ border: "1px solid #f58220", color: "#f58220", width: "170px", "&:hover": { backgroundColor: "#f8a11c", border: "1px solid #f58220", color: "#FFF" } }} variant="outlined">
									{imgIndex === imagesArray.length - 1 ? "Submit" : "Next"}
								</Button>
							</Grid>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={2} sm={1}></Grid>
			</Grid>
		</>
	);
}

export default Pattern;
