import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Amplify, Storage } from "aws-amplify";
import { useEffect, useMemo, useRef, useState } from "react";
import { createNewGame, createPatternImages, editGame, editPatternImages, getGameList, getPatternImages } from "../api/gamesApi";
import { getRefData, setGames } from "../api/refdataApi";
import { IconCats, TOAST_SETTINGS, icons } from "../config/constants";
import useToastContext from "../config/useToastContext";
import AddGame from "../dialogs/addGame";
import AddGameDialog from "../dialogs/addGameImage";

let iconNames = IconCats.flatMap((cat) => cat.name);
iconNames.unshift(" ");

let temp = [...icons];
let catted = { 1: [], 2: [], 3: [], 4: [] };
temp.forEach((icon) => {
	catted[icon.group.id].push(icon);
});

Amplify.configure({
	aws_cognito_region: "us-east-2",
	Storage: {
		AWSS3: {
			bucket: "edwebapp8a95d58d22d94fe98ac018e682e9ab48123107-dev", //REQUIRED -  Amazon S3 bucket name
			region: "us-east-2", //OPTIONAL -  Amazon service region
		},
	},
});

async function getImages(id) {
	const data = await getPatternImages(id);
	let parsed = JSON.parse(data.body);
	return parsed;
}

async function GetGames() {
	const data = await getGameList();
	let parsed = JSON.parse(data.body);
	console.log(parsed);
	return parsed;
}

const CustomAWSImage = ({ item, updateClick, imageUrl }) => {
	const [url, setUrl] = useState("");
	useEffect(() => {
		async function fetchData() {
			let tempUrl = imageUrl;
			if (tempUrl.includes("data")) {
				setUrl(imageUrl);
			} else {
				try {
					let signedURL = await Storage.get(imageUrl);
					setUrl(signedURL);
				} catch (err) {
					console.log(err);
				}
			}
		}
		fetchData();
	}, [item, imageUrl]);

	return (
		<ImageList sx={{ width: 200, height: 200, borderRadius: "5px", border: "1px solid #a9ada9" }} cols={1}>
			<ImageListItem>
				<img src={url} height="200px" width="200px" loading="lazy" alt={item.name} />
				<ImageListItemBar
					title={"Edit Image"}
					actionIcon={
						<IconButton sx={{ color: "#ffff" }} onClick={(e) => updateClick(e, item)}>
							<EditIcon />
						</IconButton>
					}
				/>
			</ImageListItem>
		</ImageList>
	);
};

const ListItemCustom = ({ item, handleScoringChange, checkScoring }) => {
	const [showScore, setShowScore] = useState(false);

	let html = [];
	html.push(
		<Grid item xs={12}>
			<IconButton
				aria-label="Show Score"
				size="large"
				onClick={() => setShowScore(!showScore)}
				sx={{
					color: "#6d7073",
					height: "50px",
					float: "left",
					marginRight: "5px",
				}}
			>
				{showScore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				<Typography sx={{ fontSize: 16, padding: "18px" }} color="#6d7073" component="div">
					<b>Show Scoring Fields</b>
				</Typography>
			</IconButton>
		</Grid>
	);

	// TODO: needs to be updated for icon cats
	for (let i = 1; i < 5; i++) {
		let iconRows = catted[i].sort((a, b) => a.sortOrder - b.sortOrder);

		html.push(
			<>
				{showScore && (
					<>
						<Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
							<Typography sx={{ fontSize: 14, padding: "18px" }} color="#6d7073" component="div">
								<b>{iconNames[i]}</b>
							</Typography>
						</Grid>
						<Grid item xs={10}>
							<Stack direction="row">
								{iconRows.map((icon) => {
									return (
										<>
											<Stack>
												<ListItemButton sx={{ display: "flex", justifyContent: "center", width: 70, height: 70, border: "1px solid #a9ada9", borderRadius: 2, backgroundColor: "#e0e0e0ad", margin: "5px" }}>
													<ListItemIcon sx={{ width: { sm: 50, md: 60, lg: 70 }, minWidth: "50px", height: { sm: 50, md: 60, lg: 70 }, padding: "7px" }}>
														<img src={icon.url} loading="lazy" alt={icon.name} />
													</ListItemIcon>
												</ListItemButton>
												<TextField name="score" sx={{ width: "60px", marginLeft: "8px", textAlignLast: "center" }} onChange={(e) => handleScoringChange(e, icon.id, item)} value={checkScoring(item, icon)} margin="dense" type="number" variant="standard" />
											</Stack>
										</>
									);
								})}
							</Stack>
						</Grid>
					</>
				)}
			</>
		);
	}
	return html;
};

function ManageGames() {
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedImages, setSelectedImages] = useState({});
	const [selectedImageIds, setSelectedImageIds] = useState([]);
	const [currentImageId, setCurrentImageId] = useState(null);
	const [files, setFiles] = useState({});
	const [imageData, setImageData] = useState({});
	const [showCreateDialog, setShowCreateDialog] = useState(false);
	const [showGameCreateDialog, setShowGameCreateDialog] = useState(false);
	const [refreshData, setRefreshData] = useState(false);
	const fileInput = useRef();
	const [images, setImages] = useState([]);
	const [gameList, setGameList] = useState([]);
	const games = useMemo(() => setGames());
	const addToast = useToastContext();
	const [selectedIndex, setSelectedIndex] = useState(0);
	let iconNames = IconCats.flatMap((cat) => cat.name);
	iconNames.unshift(" ");

	useEffect(() => {
		const fetchData = async () => {
			// needs to be the game id
			const result = await getImages(1);
			const gameResult = await GetGames();

			setGameList(gameResult);
			let fistGame = gameResult[0];
			setSelectedItem(fistGame);
			setSelectedIndex(fistGame.game_id);
			setImages(result);
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			// needs to be the game id
			const result = await getImages(selectedItem.game_id);
			setImages(result);
			setRefreshData(false);
		};
		if (refreshData) {
			fetchData();
		}
	}, [refreshData]);

	useEffect(() => {
		const fetchData = async () => {
			// needs to be the game id
			const result = await getImages(selectedItem.game_id);
			setSelectedIndex(selectedItem.game_id);
			setImages(result);
		};
		if (selectedItem) {
			fetchData();
		}
	}, [selectedItem]);

	function checkNewImageEdit(item) {
		let alreadyEditing = selectedImageIds.some((id) => id === item.image_id);
		setCurrentImageId(item.image_id);
		if (alreadyEditing) {
		} else {
			if (selectedImageIds.length !== 0) {
				addToast({
					message: "You didn't save an edited Image.",
					severity: TOAST_SETTINGS.severity.INFO,
				});
			}
			let tempIds = selectedImageIds;
			tempIds.push(item.image_id);
			setSelectedImageIds(tempIds);

			let tempItems = {
				...selectedImages,
				[item.image_id]: item,
			};
			setSelectedImages(tempItems);
		}
	}

	let temp = [...icons];
	let catted = { 1: [], 2: [], 3: [], 4: [] };
	temp.forEach((icon) => {
		catted[icon.group.id].push(icon);
	});

	const handleListItemClick = (item, id) => {
		setSelectedItem(item);
	};

	const handleSubmit = async (body) => {
		try {
			await Storage.put(body.file.name, body.file);
			const response = await createPatternImages(body);
			if (response.statusCode === 200) {
				addToast({
					message: "Successfully added image properties.",
					severity: TOAST_SETTINGS.severity.SUCCESS,
				});
				setRefreshData(true);
			} else {
				addToast({
					message: "There was an error adding the image.",
					severity: TOAST_SETTINGS.severity.ERROR,
				});
			}
		} catch {
			addToast({
				message: "There was an error adding the document in AWS.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
		setShowCreateDialog(false);
	};

	const handleGameCreateSubmit = async (body) => {
		const response = await createNewGame(body);
		if (response.statusCode === 200) {
			addToast({
				message: "Successfully Created a new game.",
				severity: TOAST_SETTINGS.severity.SUCCESS,
			});
			let parsed = JSON.parse(response.body);
			setSelectedItem(parsed[0]);
			const gameResult = await GetGames();
			setGameList(gameResult);
		} else {
			addToast({
				message: "There was an error adding the game.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}

		setShowGameCreateDialog(false);
	};

	const handleSaveGame = async () => {
		const response = await editGame(selectedItem);
		if (response.statusCode === 200) {
			addToast({
				message: "Successfully edited game properties.",
				severity: TOAST_SETTINGS.severity.SUCCESS,
			});
			await getRefData();
		} else {
			addToast({
				message: "There was an error updating the game.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
	};

	const handleSaveImage = async (id) => {
		try {
			let temp = {
				...selectedImages[id],
				...imageData[id],
			};
			let tempFile = files[id];
			if (tempFile) {
				await Storage.put(tempFile.name, tempFile);
				temp.image_url = tempFile.name;
			}
			temp.image_scoring = typeof temp.image_scoring !== "string" ? temp.image_scoring : JSON.parse(temp.image_scoring);
			const response = await editPatternImages(temp);
			if (response.statusCode === 200) {
				addToast({
					message: "Successfully edited image properties.",
					severity: TOAST_SETTINGS.severity.SUCCESS,
				});
				let t = {};
				if (selectedImages[id]) {
					t = {
						...selectedImages,
					};
					delete t[id];
					setSelectedImages(t);
				}
				if (files[id]) {
					t = {
						...files,
					};
					delete t[id];
					setFiles(t);
				}
				if (imageData[id]) {
					t = {
						...imageData,
					};
					delete t[id];
					setImageData(t);
				}
				t = [];
				selectedImageIds.forEach((imageId) => {
					if (imageId !== id) {
						t.push(imageId);
					}
				});
				setSelectedImageIds(t);
			} else {
				addToast({
					message: "There was an error updating the image.",
					severity: TOAST_SETTINGS.severity.ERROR,
				});
			}
		} catch {
			addToast({
				message: "There was an error adding the document in AWS.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
	};

	const handleStringChange = (event) => {
		event.persist();
		setSelectedItem({
			...selectedItem,
			[event.target.name]: String(event.target.value),
		});
	};

	const handleImageStringChange = (event, item) => {
		checkNewImageEdit(item);
		let scoreProps = imageData[item.image_id];
		event.persist();
		let tempItems = {
			...imageData,
			[item.image_id]: {
				...scoreProps,
				[event.target.name]: String(event.target.value),
			},
		};
		setImageData(tempItems);
	};

	const handleNumberChange = (event) => {
		event.persist();
		setSelectedItem({
			...selectedItem,
			[event.target.name]: Number(event.target.value),
		});
	};

	const checkScoring = (item, icon) => {
		let scoring = typeof item.image_scoring !== "string" ? item.image_scoring : JSON.parse(item.image_scoring);
		let v = 0;
		for (let i = 0; i < scoring.length; i++) {
			if (scoring[i][icon.id] !== undefined) {
				v = scoring[i][icon.id];
				break;
			}
		}

		return v;
	};

	const handleScoringChange = (event, id, item) => {
		checkNewImageEdit(item);
		event.preventDefault();
		let value = Number(event.target.value);
		let nameProps = imageData[item.image_id];
		let temp = { ...item, ...nameProps };
		temp.image_scoring = typeof item.image_scoring !== "string" ? item.image_scoring : JSON.parse(item.image_scoring);
		if (temp.image_scoring.length === 0) {
			temp.image_scoring.push({ [id]: value });
		} else {
			for (let i = 0; i < temp.image_scoring.length; i++) {
				if (temp.image_scoring[i][id] !== undefined) {
					temp.image_scoring[i][id] = value;
					break;
				}
				if (i === temp.image_scoring.length - 1) {
					temp.image_scoring.push({ [id]: value });
					break;
				}
			}
		}
		let tempItem = [...images];
		let newArray = tempItem.map((image) => {
			if (image.image_id === temp.image_id) {
				image = temp;
			}
			return image;
		});

		setImageData({ ...imageData, [temp.image_id]: { ...temp } });
		setImages(newArray);
	};

	const handleUpdate = (e) => {
		e.preventDefault();
		let file = e.target.files[0];
		const reader = new FileReader();
		let tempfile = {
			...files,
			[currentImageId]: file,
		};
		setFiles(tempfile);

		reader.onloadend = () => {
			let temp = [...images];

			temp.forEach((image) => {
				if (image.image_id === currentImageId) {
					image.image_url = reader.result;
				}
			});
			setImages(temp);
		};
		reader.readAsDataURL(file);
		e.target.value = null;
	};

	function updateClick(event, item) {
		checkNewImageEdit(item);
		fileInput.current.click();
	}

	return (
		<>
			<Box variant="outlined" sx={{ display: "flex", alignItems: "center", backgroundColor: "white", borderRadius: 2, border: 1, borderColor: "#e0e0e0" }}>
				<Grid container spacing={2} direction="row">
					<Grid item xs={2}></Grid>
					<Grid item xs={8} sx={{ textAlign: "center" }}>
						<Typography sx={{ fontSize: 16, padding: "18px" }} color="#6d7073" component="div">
							<b>Manage Games</b>
						</Typography>
					</Grid>
					<Grid item xs={2} sx={{ alignSelf: "center" }}>
						<Tooltip title={`Add Image`}>
							<IconButton
								aria-label="add image"
								size="large"
								onClick={() => setShowCreateDialog(true)}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
									float: "right",
									marginRight: "5px",
								}}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item container sx={{ margin: "10px" }}>
						<Grid item xs={2}>
							<List component="nav" aria-label="games">
								<ListItem sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
									<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
										<b>Game List</b>
									</Typography>
								</ListItem>
								{gameList.map((item) => {
									return (
										<ListItemButton sx={{ borderRadius: 3, textAlign: "center", marginLeft: "20px", marginRight: "20px", marginBottom: "10px" }} selected={selectedIndex === item.game_id} onClick={() => handleListItemClick(item)}>
											<ListItemText primary={item.game_name} />
										</ListItemButton>
									);
								})}
								<ListItemButton sx={{ borderRadius: 3, textAlign: "center", marginLeft: "20px", marginRight: "20px", marginBottom: "10px", backgroundColor: "#d3d3d33b" }} onClick={() => setShowGameCreateDialog(true)}>
									<ListItemText primary="Add New Game" sx={{ flexGrow: 1 }} />
								</ListItemButton>
							</List>
						</Grid>
						<Grid item xs={10} sx={{ overflowY: "scroll", height: "72vh" }}>
							<Box sx={{ display: "flex", alignItems: "center", backgroundColor: "white", marginLeft: "10px" }}>
								<Grid item container sx={{ border: "1px solid #e0e0e0", borderRadius: 2, marginTop: "5px", padding: "10px" }}>
									<Grid item xs={2} sx={{ alignSelf: "center" }}>
										{" "}
									</Grid>
									<Grid item xs={8} sx={{ alignSelf: "center" }}>
										<Typography sx={{ fontSize: 14, alignSelf: "center", display: "flex", justifyContent: "center" }} color="text.secondary" gutterBottom>
											<b>Game Properties</b>
										</Typography>
									</Grid>
									<Grid item xs={2} sx={{ alignSelf: "center" }}>
										<Tooltip title={`Save Game properties`}>
											<IconButton
												aria-label="add image"
												size="large"
												onClick={() => handleSaveGame()}
												sx={{
													color: "#6d7073",
													"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
													float: "right",
													marginRight: "5px",
												}}
											>
												<SaveIcon />
											</IconButton>
										</Tooltip>
									</Grid>
									<Grid item xs={2} sx={{ alignSelf: "center" }}>
										<Typography sx={{ fontSize: 14, alignSelf: "center" }} color="text.secondary" gutterBottom>
											<b>Game Name:</b>
										</Typography>
									</Grid>
									<Grid item xs={10}>
										<TextField name="game_name" InputLabelProps={{ shrink: true }} value={selectedItem?.game_name} margin="dense" fullWidth label="Game Name" type="string" variant="outlined" onChange={handleStringChange} />
									</Grid>
									<Grid item xs={2} sx={{ alignSelf: "center" }}>
										<Typography sx={{ fontSize: 14, alignSelf: "center" }} color="text.secondary" gutterBottom>
											<b>Question Amount:</b>
										</Typography>
									</Grid>
									<Grid item xs={10}>
										<TextField name="question_amount" InputLabelProps={{ shrink: true }} value={selectedItem?.question_amount} margin="dense" fullWidth label="Question Amount" type="string" variant="outlined" onChange={handleNumberChange} />
									</Grid>
								</Grid>
							</Box>
							<Box sx={{ display: "flex", alignItems: "center", backgroundColor: "white", marginLeft: "10px" }}>
								<Grid item container sx={{ marginTop: "20px" }}>
									{images.map((item) => {
										return (
											<Grid item container sx={{ border: "1px solid #e0e0e0", borderRadius: 2, marginTop: "5px" }}>
												<Grid item xs={2}></Grid>
												<Grid item xs={8} sx={{ display: "flex", justifyContent: "center" }}>
													<input
														type="file"
														onChange={(e) => {
															handleUpdate(e);
														}}
														ref={(el) => {
															fileInput.current = el;
														}}
														hidden
													/>
													<CustomAWSImage item={item} imageUrl={item.image_url} updateClick={updateClick} />
												</Grid>
												<Grid item xs={2}>
													<Tooltip title={`Save Image properties`}>
														<IconButton
															aria-label="add image"
															size="large"
															onClick={() => handleSaveImage(item.image_id)}
															sx={{
																color: "#6d7073",
																"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
																float: "right",
																marginRight: "15px",
															}}
															disabled={!selectedImageIds?.some((id) => id === item.image_id)}
														>
															<SaveIcon />
														</IconButton>
													</Tooltip>
												</Grid>
												<Grid item xs={2}></Grid>
												<Grid item xs={3} sx={{ alignSelf: "center", textAlign: "end", marginRight: "20px" }}>
													<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
														<b>Image Name:</b>
													</Typography>
												</Grid>
												<Grid item xs={4}>
													<TextField name="image_name" key={`text:${item.image_id}`} defaultValue={item.image_name} margin="dense" fullWidth label="Image Name" type="string" variant="outlined" onChange={(e) => handleImageStringChange(e, item)} required />
												</Grid>
												<Grid item xs={3}></Grid>
												<Grid item container>
													<ListItemCustom item={item} handleScoringChange={handleScoringChange} checkScoring={checkScoring} />
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<AddGameDialog open={showCreateDialog} handleCancel={() => setShowCreateDialog(false)} handleSubmit={handleSubmit} game={selectedItem}></AddGameDialog>
			<AddGame open={showGameCreateDialog} handleCancel={() => setShowGameCreateDialog(false)} handleSubmit={handleGameCreateSubmit} />
		</>
	);
}

export default ManageGames;
