import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
//import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Amplify } from "aws-amplify";
import { useEffect, useState } from "react";
import { createSchool, editSchool, getSchools } from "../api/schoolsApi";
import { schoolCols } from "../columns/schoolColumns";
import { TOAST_SETTINGS } from "../config/constants";
import useToastContext from "../config/useToastContext";
import SchoolDialog from "../dialogs/schoolDialog";

let colVisModel = {
	email: false,
	last_name: false,
};

Amplify.configure({
	aws_cognito_region: "us-west-2",
	Auth: {
		identityPoolId: "us-west-2:b1edff8c-7ab4-4757-b91a-6f3db23408e2", // REQUIRED - Amazon Cognito Identity Pool ID
		region: "us-west-2", // REQUIRED - Amazon Cognito Region
		userPoolId: "us-west-2_sXLDbiySM",
		userPoolWebClientId: "3lh3i1cfsc7agu02dvtnatcpj6",
	},
});

async function CreateSchool(school) {
	const response = await createSchool(school);
	return response;
}

async function GetSchools() {
	const response = await getSchools();
	// let parsed = JSON.parse(response.body).filter((item) => item.is_teacher != 1);
	let parsed = JSON.parse(response.body);
	return parsed;
}

async function EditSchool(body) {
	const response = await editSchool(body);
	return response;
}

function ManageSchools({ navUser }) {
	const [data, setData] = useState([]);
	const [columns, setColumns] = useState(schoolCols);
	const [showCreateDialog, setShowCreateDialog] = useState(false);
	const addToast = useToastContext();
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(colVisModel);
	const [refresh, setRefresh] = useState(false);
	const [selectedSchool, setSelectedSchool] = useState(null);

	const handleSubmitSchools = async (schoolData) => {
		let isEdit = schoolData.hasOwnProperty("school_id");
		const response = isEdit ? await EditSchool(schoolData) : await CreateSchool(schoolData);
		if (response.statusCode === 200) {
			addToast({
				message: "School was successfully created.",
				severity: TOAST_SETTINGS.severity.SUCCESS,
			});
			setRefresh(true);
			setShowCreateDialog(false);
			setSelectedSchool(null);
		} else {
			addToast({
				message: "There was an error creating the school.",
				severity: TOAST_SETTINGS.severity.ERROR,
			});
		}
	};

	const handleEditSchoolClick = async (params) => {
		setSelectedSchool(params?.row);
		setShowCreateDialog(true);
	};

	useEffect(() => {
		setColumns([
			...schoolCols,
			{
				field: "actions",
				type: "actions",
				width: 170,
				getActions: (params) => {
					return [
						<Tooltip title="Edit School">
							<GridActionsCellItem
								icon={<EditIcon />}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
								}}
								label="Edit School"
								className="textPrimary"
								color="inherit"
								onClick={() => handleEditSchoolClick(params)}
							/>
						</Tooltip>,
					];
				},
			},
		]);

		const fetchData = async () => {
			const result = await GetSchools();
			setData(result);
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const result = await GetSchools();

			setData(result);
		};
		if (refresh) {
			fetchData();
			setRefresh(false);
		}
	}, [refresh]);

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
			</GridToolbarContainer>
		);
	}

	return (
		<>
			<Box variant="outlined" sx={{ display: "flex", alignItems: "center", backgroundColor: "white", borderRadius: 2, border: 1, borderColor: "#e0e0e0", alignContent: "center", textAlign: "center" }}>
				<Grid container spacing={2} direction="row">
					<Grid item xs={2}></Grid>
					<Grid item xs={8} sx={{ textAlign: "center" }}>
						<Typography sx={{ fontSize: 16, padding: "18px" }} color="#6d7073" component="div">
							<b>Manage Schools</b>
						</Typography>
					</Grid>
					<Grid item xs={2} sx={{ alignSelf: "center" }}>
						<Tooltip title={`Add School`}>
							<IconButton
								aria-label="Add School"
								size="large"
								onClick={() => setShowCreateDialog(true)}
								sx={{
									color: "#6d7073",
									"&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
									float: "right",
									marginRight: "5px",
								}}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item xs={12} sx={{ marginRight: "10px", marginLeft: "10px", marginBottom: "10px", paddingRight: "20px !important", borderWidth: 4, borderColor: "red" }}>
						<DataGrid
							rows={data}
							columns={columns}
							getRowId={(row) => row["school_id"]}
							columnVisibilityModel={columnVisibilityModel}
							onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
							pagination
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[5, 10, 25]}
							density="standard"
							disableVirtualization
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
					</Grid>
				</Grid>
			</Box>
			<SchoolDialog
				open={showCreateDialog}
				handleCancel={() => {
					setShowCreateDialog(false);
					setSelectedSchool(null);
				}}
				handleSubmit={handleSubmitSchools}
				user={navUser}
				school={selectedSchool}
			></SchoolDialog>
		</>
	);
}

export default ManageSchools;
