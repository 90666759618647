import { API, Amplify, Auth } from "aws-amplify";

Amplify.configure({
	aws_cognito_region: "us-east-2",
	Auth: {
		identityPoolId: "us-east-2:4afb7022-c53b-431a-b5ee-7c2499ce04f5", // REQUIRED - Amazon Cognito Identity Pool ID
		region: "us-east-2", // REQUIRED - Amazon Cognito Region
		userPoolId: "us-east-2_PHjtSQXtJ",
		userPoolWebClientId: "7d3fniusemp2e47u8a7mhkti4t",
	},
	API: {
		endpoints: [
			{
				name: "apiGateway",
				endpoint: "https://zfmaj09w42.execute-api.us-east-2.amazonaws.com/dev",
			},
		],
	},
});

export async function createGame(body) {
	const apiName = "apiGateway";
	const path = `/games/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.put(apiName, path, myInit);
}

export async function addResult(body) {
	const apiName = "apiGateway";
	const path = `/games/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.post(apiName, path, myInit);
}

export async function getResults(body) {
	const apiName = "apiGateway";
	const path = `/results/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.post(apiName, path, myInit);
}

export async function finishGame(body) {
	const apiName = "apiGateway";
	const path = `/assign/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.put(apiName, path, myInit);
}

export async function getPatternImages(id) {
	const apiName = "apiGateway";
	const path = `/images?id='${id}'`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
	};

	return API.get(apiName, path, myInit);
}

export async function editPatternImages(body) {
	const apiName = "apiGateway";
	const path = `/images/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.post(apiName, path, myInit);
}

export async function createPatternImages(body) {
	const apiName = "apiGateway";
	const path = `/images/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.put(apiName, path, myInit);
}

export async function editGame(body) {
	const apiName = "apiGateway";
	const path = `/gamemanagement/`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.post(apiName, path, myInit);
}

export async function getGameList() {
	const apiName = "apiGateway";
	const path = `/gamemanagement`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
	};

	return API.get(apiName, path, myInit);
}

export async function createNewGame(body) {
	const apiName = "apiGateway";
	const path = `/gamemanagement`;
	let myInit = {
		headers: {
			Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			body,
		},
	};

	return API.put(apiName, path, myInit);
}
