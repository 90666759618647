import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createGame } from "../api/gamesApi";

async function startGame(body) {
  const data = await createGame(body);
  let parsed = JSON.parse(data.body);
  return parsed;
}

function StartGame({ user }) {
  const [gameUser, setGameUser] = useState({});
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      setGameUser(state.user);
    } else if (user) {
      setGameUser(user);
    }
  }, [user, state]);

  const playGame = async (id) => {
    let body = {
      game_id: id,
      user_id: gameUser.id,
    };
    const response = await startGame(body);
    let temp = {
      state: {
        game: response,
        user: gameUser,
      },
    };
    navigate("/pattern", temp);
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography style={{ alignSelf: "center", marginBottom: 25 }}>
          <b>
            Welcome {gameUser?.first_name} {gameUser?.last_name}!
          </b>
        </Typography>
        {gameUser?.games_assigned?.length > 0 ? (
          <Typography style={{ alignSelf: "center", marginBottom: 20 }}>You have {gameUser?.games_assigned?.length == 1 ? "a game" : "some games"} to play!</Typography>
        ) : (
          <Typography style={{ alignSelf: "center", marginBottom: 20 }}>You have played all your games!</Typography>
        )}

        {gameUser?.games_assigned?.map((game) => {
          return (
            <>
              <Typography style={{ alignSelf: "center", marginBottom: "5px" }}>The {game.game_name} Game</Typography>
              <Button onClick={() => playGame(game.game_id)} sx={{ border: "1px solid #f58220", color: "#f58220", width: "170px", "&:hover": { backgroundColor: "#f8a11c", border: "1px solid #f58220", color: "#FFF" } }} variant="outlined">
                Play
              </Button>
            </>
          );
        })}
      </Box>
    </>
  );
}

export default StartGame;
