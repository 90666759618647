import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Input, InputAdornment } from "@mui/material";
import { debounce } from "lodash";
import { useRef } from "react";

export default function SearchBar({ onChange, style }) {
  const inputRef = useRef(null);

  const debouncedChangeHandler = debounce(onChange, 200);

  const handleChange = (event) => {
    debouncedChangeHandler(event.target.value);
  };

  const clearValue = () => {
    inputRef.current.value = "";
    debouncedChangeHandler("");
  };
  return (
    <Input
      onChange={handleChange}
      id="search"
      style={style}
      type="text"
      fullWidth
      inputRef={inputRef}
      endAdornment={
        !inputRef?.current?.value ? (
          <InputAdornment position="end">
            <SearchIcon></SearchIcon>
          </InputAdornment>
        ) : (
          <IconButton onClick={clearValue}>
            <ClearIcon />
          </IconButton>
        )
      }
    ></Input>
  );
}
