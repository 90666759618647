import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Amplify, Storage } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { getResults } from "../api/gamesApi";
import { setSchools } from "../api/refdataApi";
import ProgressLine from "../components/progressLine";
import { icons } from "../config/constants";

let tempIcons = [...icons];
async function getGameResults(body) {
	const data = await getResults(body);
	let parsed = JSON.parse(data.body);
	return parsed;
}

Amplify.configure({
	aws_cognito_region: "us-west-2",
	Storage: {
		AWSS3: {
			bucket: "abcpattern7840fd48a68b49eb926c12323e49d94f82232-abctestpat", //REQUIRED -  Amazon S3 bucket name
			region: "us-west-2", //OPTIONAL -  Amazon service region
		},
	},
});

function GetSignedUrl({ url, name }) {
	const [signed, setSigned] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			const result = await Storage.get(url);
			setSigned(result);
		};
		fetchData();
	}, [url]);

	return <img src={signed} loading="lazy" height={"148px"} width={"148px"} alt={name} />;
}

export default function StudentInfoDialog({ open, handleCancel, user, navUser, handleSubmit }) {
	let student = user;

	const [results, setResults] = useState([]);
	const [canEdit, setCanEdit] = useState(false);
	const [isTeacher, setIsTeacher] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [totalTime, setTotalTime] = useState(0);
	const [schoolId, setSchoolId] = useState(student?.school?.school_id ?? 99);
	const [studentData, setStudentData] = useState({});
	const schools = useMemo(() => setSchools());

	const handeChildTransform = (child) => {
		console.log("here");
		// handle the click time
		let ct = JSON.parse(child?.click_time).map((item) => (item < 1 ? 1 : item));
		let tt = 0;
		ct.forEach((time) => {
			tt += time;
		});
		let poss = 0;
		let _correct_icons = JSON.parse(child?.correct_icons).map((icon) => {
			let key = Object.keys(icon);
			let value = icon[key];
			let temp = tempIcons.find((t) => {
				return t.id == Number(key);
			});
			poss += value;
			return { ...temp, score: value };
		});

		let stu_score = 0;
		let score_breakdown = [];
		let _selected_icons = JSON.parse(child?.selected_icons).map((icon) => {
			let key = Object.keys(icon);
			let value = icon[key];
			let temp = tempIcons.find((t) => {
				return t.id == Number(key);
			});
			stu_score += value === -1 ? 0 : value;
			if (value > 0) {
				score_breakdown.push({
					value: value,
					group: temp.group,
				});
			}
			return { ...temp, score: value };
		});

		setTotalTime((prevTime) => prevTime + tt);

		let temp = {
			image: child.image,
			total_time: tt,
			click_time: ct,
			correct_icons: _correct_icons,
			selected_icons: _selected_icons,
			possible_score: poss,
			student_total: stu_score,
			student_score: score_breakdown,
		};
		return temp;
	};

	useEffect(() => {
		setIsTeacher(user.is_teacher);
		setIsAdmin(user.is_admin);
		setSchoolId(user?.school?.school_id);
	}, [user]);

	useEffect(() => {
		if (open) {
			setTotalTime(0);
			const fetchData = async () => {
				navUser.is_admin || navUser.is_teacher ? setCanEdit(true) : setCanEdit(false);
				const result = await getGameResults({ id: user?.id });
				// etl for results
				let parent = [];
				let child = [];

				for (let i = 0; i < result.length; i++) {
					if (i == 0) {
						child.push(handeChildTransform(result[i]));
					} else if (child.length == 0) {
						child.push(handeChildTransform(result[i]));
					} else if (result[i].game_taken_id == result[i - 1].game_taken_id) {
						child.push(handeChildTransform(result[i]));
					} else if (result[i].game_taken_id != result[i - 1].game_taken_id) {
						let scored = 0;
						let possible = 0;
						child.forEach((item) => {
							scored += item.student_total;
							possible += item.possible_score;
						});
						let temp = {
							game: result[i].game,
							date_time: result[i].date_time,
							results: child,
							score: { scored: scored, possible: possible },
						};
						parent.push(temp);
						child = [handeChildTransform(result[i])];
					}

					if (i == result.length - 1) {
						let scored = 0;
						let possible = 0;
						child.forEach((item) => {
							scored += item.student_total;
							possible += item.possible_score;
						});
						let temp = {
							game: result[i].game,
							date_time: result[i].date_time,
							id: result[i].id,
							results: child,
							score: { scored: scored, possible: possible },
						};
						parent.push(temp);
					}
				}
				setResults(parent);
			};
			fetchData();
		}
	}, [open]);

	const handleClose = (update) => {
		if (update) {
			let student = {
				...studentData,
				is_admin: isAdmin,
				is_teacher: isTeacher,
				school_id: schoolId,
				user_id: user.id,
			};
			handleSubmit(student);
		} else {
			handleCancel();
			setStudentData({});
		}
	};
	const getBackgroundColor = (icon, compareArray) => {
		let color = "#FFF";
		if (compareArray.some((item) => item.id === icon.id)) {
			color = "#21b25963";
		}

		// this means they deselected it
		if (icon.score === -1) {
			color = "#34d7e530";
		}

		return color;
	};

	function stringAvatar(name, height, width, fontsize) {
		let init = "";

		if (name.search(" ") === -1) {
			init = name.substring(0, 1);
		} else {
			init = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
		}

		return {
			style: {
				backgroundColor: stringToColor(name),
				height: height,
				width: width,
				fontSize: fontsize,
			},
			children: init.toUpperCase(),
		};
	}

	function stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */
		return color;
	}

	const handleSchoolChange = (event) => {
		setSchoolId(Number(event.target.value));
	};

	const handleStringChange = (event) => {
		event.persist();
		setStudentData({
			...studentData,
			[event.target.name]: String(event.target.value),
		});
	};

	const handleNumberChange = (event) => {
		event.persist();
		setStudentData({
			...studentData,
			[event.target.name]: Number(event.target.value),
		});
	};

	let color = [" ", "#6084c3", "#f8a11c", "#00a78b", "#ef3d29"];
	let timeColor = ["#252c86", "#777cc0", "#3b48ff", "#101557"];

	return (
		<Box sx={{ padding: "15px" }}>
			<Dialog open={open} maxWidth="900px">
				<DialogTitle>
					<Typography variant="h6" align="center">
						{student?.is_teacher ? "Teacher Details" : "Student Details"}
					</Typography>
				</DialogTitle>
				<DialogContent sx={{ maxWidth: "900px", minWidth: "900px" }} align="left">
					<Grid container spacing={2} sx={{ marginTop: "5px" }}>
						<Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
							<Stack>
								<Avatar {...stringAvatar(`${student.first_name} ${student.last_name}`, 120, 120, "2rem")} />
								<Typography align="center" sx={{ mt: 2 }}>
									<b>Tests Taken: {results.length}</b>
								</Typography>
								<Typography align="center">
									<b>Time Taken: {new Date(totalTime * 1000).toISOString().substring(14, 19)}</b>
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={8}>
							{canEdit && (
								<Stack direction="row">
									<FormControlLabel control={<Checkbox checked={Boolean(isTeacher)} onChange={() => setIsTeacher(!isTeacher)} />} label="Teacher Account" sx={{ pr: "10px" }} />
									{navUser?.is_admin ? <FormControlLabel control={<Checkbox checked={Boolean(isAdmin)} onChange={() => setIsAdmin(!isAdmin)} />} label="Admin Account" /> : <></>}
								</Stack>
							)}
							<TextField
								name="first_name"
								margin="dense"
								InputLabelProps={{
									shrink: true,
									padding: "5px",
								}}
								size="small"
								defaultValue={student.first_name}
								fullWidth
								label="First Name"
								type="string"
								variant="outlined"
								disabled={!canEdit}
								onChange={handleStringChange}
							/>
							<TextField name="last_name" size="small" margin="dense" defaultValue={student.last_name} fullWidth label="Last Name" type="string" variant="outlined" disabled={!canEdit} onChange={handleStringChange} />
							<TextField name="email" size="small" margin="dense" defaultValue={student.email} fullWidth label="Email" type="string" variant="outlined" disabled={!canEdit} onChange={handleStringChange} />
							{schoolId && (
								<FormControl variant="outlined" fullWidth sx={{ mt: "8px" }} size="small">
									<InputLabel id="contact-type-label">School *</InputLabel>
									<Select name="school_id" value={schoolId} onChange={handleSchoolChange} fullWidth={true} label="School *">
										{schools.map((school) => {
											return (
												<MenuItem key={school.school_id} value={school.school_id}>
													{school.school_name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
							<TextField name="grade" size="small" margin="dense" defaultValue={student.grade} fullWidth label="Grade" type="number" variant="outlined" onChange={handleNumberChange} disabled={!canEdit} />
						</Grid>
						<Grid item xs={12}>
							{results?.map((results) => {
								return (
									<Accordion TransitionProps={{ unmountOnExit: true }}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography sx={{ marginRight: "10px" }}>
												<b>
													{results.game.name} Results ({results.score?.scored}/{results.score?.possible})
												</b>
											</Typography>
											<Typography>
												<i>{results.date_time}</i>
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container spacing={2} sx={{ alignItems: "center" }}>
												{results.results.map((res) => {
													let scoreProgress = res.student_score.map((score) => {
														let r = {
															percentage: `${Math.round((score.value / res.possible_score) * 100)}%`,
															color: color[score.group.id],
															label: `${score.group.name}: ${score.value}`,
														};
														return r;
													});

													let timeProgress = res.click_time.map((time, index) => {
														let r = {
															percentage: `${Math.round((time / res.total_time) * 100)}%`,
															color: timeColor[index],
															label: time + " seconds",
														};
														return r;
													});

													let resCount = Math.ceil(res.selected_icons.length / 7);
													return (
														<>
															<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
																<ImageList sx={{ width: 150, height: 150, borderRadius: "5px", border: "1px solid #a9ada9" }} cols={1}>
																	<ImageListItem sx={{ display: "flex", justifyContent: "center" }}>
																		<GetSignedUrl url={res.image.url} name={res.image.name} />

																		<ImageListItemBar title={res.image.name} />
																	</ImageListItem>
																</ImageList>
															</Grid>
															<Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
																<Typography>Time Taken ({res.total_time} sec):</Typography>
															</Grid>
															<Grid item xs={9}>
																<Typography>
																	<ProgressLine backgroundColor={timeColor[res.click_time.length - 1]} visualParts={timeProgress} />
																</Typography>
															</Grid>
															<Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
																<Typography>Image Score:</Typography>
															</Grid>
															<Grid item xs={9}>
																<ProgressLine backgroundColor="#e4e4e4" visualParts={scoreProgress} />
															</Grid>
															<Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
																<Typography>Student Patterns(s):</Typography>
															</Grid>
															<Grid item xs={9} sx={{ display: "flex", justifyContent: "center" }}>
																<ImageList sx={{ width: 575, height: { sm: 60 * resCount, md: 70 * resCount, lg: 80 * resCount }, backgroundColor: "#dcdcdc", marginTop: "0px", border: "1px solid #e4e4e4", borderRadius: 2, padding: "5px", overflow: "hidden" }} cols={7}>
																	{res.selected_icons.map((item) => (
																		<ImageListItem
																			key={item.id}
																			sx={{ width: { sm: 50, md: 60, lg: 70 }, backgroundColor: getBackgroundColor(item, res.correct_icons), marginLeft: "5px", minWidth: "50px", height: { sm: 50, md: 60, lg: 70 }, padding: "5px", border: "1px solid #a9ada9", borderRadius: 2 }}
																		>
																			<img src={item.url} loading="lazy" alt={item.name} />
																			{item.score !== 0 && item.score !== -1 && <ImageListItemBar title={item.score} sx={{ height: 20, borderRadius: 2, textAlign: "center" }} />}
																		</ImageListItem>
																	))}
																</ImageList>
															</Grid>
															<Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
																<Typography>Correct Patterns(s):</Typography>
															</Grid>
															<Grid item xs={9} sx={{ display: "flex", justifyContent: "center" }}>
																<ImageList sx={{ width: 575, height: { sm: 60, md: 70, lg: 80 }, backgroundColor: "#dcdcdc", marginTop: "0px", border: "1px solid #e4e4e4", borderRadius: 2, padding: "5px", overflow: "hidden" }} cols={9}>
																	{res.correct_icons.map((item) => (
																		<ImageListItem
																			key={item.id}
																			sx={{ width: { sm: 50, md: 60, lg: 70 }, backgroundColor: getBackgroundColor(item.id, res.selected_icons), marginLeft: "5px", minWidth: "50px", height: { sm: 50, md: 60, lg: 70 }, padding: "5px", border: "1px solid #a9ada9", borderRadius: 2 }}
																		>
																			<img src={item.url} loading="lazy" alt={item.name} />
																			<ImageListItemBar title={item.score} sx={{ height: 20, borderRadius: 2, textAlign: "center" }} />
																		</ImageListItem>
																	))}
																</ImageList>
															</Grid>
														</>
													);
												})}
											</Grid>
										</AccordionDetails>
									</Accordion>
								);
							})}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} color="primary">
						Close
					</Button>
					{canEdit && (
						<Button onClick={() => handleClose(true)} color="primary">
							Save
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</Box>
	);
}
